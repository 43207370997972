import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    open: false,
  },
  reducers: {
    opened: (state) => {
      state.open = !state.open;
    },
    closed: (state) => {
      state.open = false;
    },
  },
});

export const { opened, closed } = sidebarSlice.actions;
export default sidebarSlice.reducer;
