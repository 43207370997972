import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    _id: null,
    products: [],
    quantity: 0,
    total: 0,
    isFetching: false,
    error: false,
  },
  reducers: {
    resetCart: (state) => {
      state._id = null;
      state.products = [];
      state.quantity = 0;
      state.total = 0;
    },
    addToCartStart: (state) => {
      state.isFetching = true;
    },
    addToCartSuccess: (state, action) => {
      state.isFetching = false;
      state._id = action.payload._id;
      state.products = action.payload.products.map((product) => product.productId);
      state.quantity = state.products.reduce(
        (acc, product) => acc + product.productId.quantity,
        0
      );
    },
    addToCartFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    getCartStart: (state) => {
      state.isFetching = true;
    },
    getCartSuccess: (state, action) => {
      state.isFetching = false;
      state._id = action.payload._id;
      if (action.payload.products.length > 0) {
        state.products = action.payload.products.map((product) => product.productId);
      }
      // some all payload products quantity
      state.quantity = state.products.length;
    },
    getCartFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    updateCartStart: (state) => {
      state.isFetching = true;
    },
    updateCartSuccess: (state, action) => {
      state.isFetching = false;
      state._id = action.payload._id;
      state.products = action.payload.products.map((product) => product.productId);

      state.quantity = state.products.reduce(
        (acc, product) => acc + product.productId.quantity,
        0
      );
    },
    updateCartFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    deleteFromCartStart: (state) => {
      state.isFetching = true;
    },
    deleteFromCartSuccess: (state, action) => {
      state.isFetching = false;
      state._id = action.payload._id;
      const productIndex = state.products.findIndex(
        (product) => product.id === action.payload.productId
      );
      state.products.splice(productIndex, 1);
    },
    deleteFromCartFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
    addProduct: (state, action) => {
      const newProduct = action.payload;
      // find if product already exists in cart
      const isExistingProduct = state.products.find((product) => {
        return (
          product._id ===
          newProduct._id
        );
      });
      if (!isExistingProduct) {
        // if product does not exist, add product to cart
        state.products.push({ ...newProduct });
        state.quantity += 1;
        state.total = state.quantity;
      }
    },
    deleteProduct: (state, action) => {
      const product = action.payload;
      const productIndex = state.products.findIndex(
        (productInCart) => productInCart._id === product._id
      );
      state.products.splice(productIndex, 1);
      state.quantity -= 1;
      state.total = state.quantity;
    },
  },
});

export const {
  resetCart,
  addProduct,
  deleteProduct,
  getCartStart,
  getCartSuccess,
  getCartFailure,
  addToCartStart,
  addToCartSuccess,
  addToCartFailure,
  updateCartStart,
  updateCartSuccess,
  updateCartFailure,
  deleteFromCartStart,
  deleteFromCartSuccess,
  deleteFromCartFailure,
} = cartSlice.actions;

export default cartSlice.reducer;
