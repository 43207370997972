import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    _id: null,
    products: [],
    amount: 0,
    address: "",
    status: "",
    isFetching: false,
    error: false,
    session: null,
  },
  reducers: {
    resetOrder: (state) => {
      state._id = null;
      state.products = [];
      state.amount = 0;
      state.address = "";
      state.status = "";
      state.session = null;
    },
    createOrderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    createOrderSuccess: (state, action) => {
      state.isFetching = false;
      state._id = action.payload._id;
      state.products = action.payload.products;
      state.amount = action.payload.amount;
      state.address = action.payload.address;
      state.status = action.payload.status;
    },
    createOrderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  resetOrder,
  createOrderStart,
  createOrderSuccess,
  createOrderFailure,
} = orderSlice.actions;

export default orderSlice.reducer;
