import styled from "styled-components";
import { mobile } from "../responsive";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/apiRequests";
import { useEffect, useState } from "react";
import { resetError } from "../redux/userRedux";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import loaderSvg from "../assets/loader.svg";

const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
`;

const TextWrapper = styled.div`
  width: 25%;
  padding: 20px;
  text-align: center;
  ${mobile({ width: "75%" })}
`;

const Wrapper = styled.div`
  width: 25%;
  padding: 20px;
  border: 1px solid #ccc;
  ${mobile({ width: "75%" })}
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  padding: 10px;
  margin: 10px 0px;
  border: 1px solid #ccc;
`;

const Button = styled.button`
  width: 40%;
  padding: 10px;
  background-color: #000;
  border: none;
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
`;

const CustomLink = styled(Link)`
  margin: 10px 0px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: #000 !important;
`;

const Error = styled.span`
  color: red;
  text-align: center;
  font-size: 12px;
  margin: 10px 0px;
`;

const Text = styled.span`
  font-size: 12px;
  text-align: center;
`;

const Image = styled.img`
  width: 110%;
  height: 20px;
`;

const Login = () => {
  const sidebarState = useSelector((state) => state.sidebar.open);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { isFetching, error } = useSelector((state) => state.user);
  const history = useHistory();
  const user = useSelector((state) => state.user.currentUser);
  const [t, i18n] = useTranslation("common");

  useEffect(() => {
    dispatch(resetError());
  }, [username, password]);

  const handleClick = (e) => {
    e.preventDefault();
    login(dispatch, { username, password }, user);
  };

  return (
    <Container sidebarState={sidebarState}>
      <Helmet>
        <title>{t("login.title")} - Laboratoire Luxe Cosmétique</title>
      </Helmet>
      {history.location.state && history.location.state.fromCart && (
        <TextWrapper>
          <Text>
            {t("login.cart_login")} {` `}
            <CustomLink
              style={{ fontWeight: "bold" }}
              to={{
                pathname: "/register",
                state: { fromCart: true },
              }}
            >
              {t("login.cart_register")}
            </CustomLink>
          </Text>
        </TextWrapper>
      )}
      <Wrapper>
        <Title>{t("login.title")}</Title>
        <Form>
          <Error>{error && <Text>{t("login.form.incorrect")}</Text>}</Error>
          <Input
            placeholder={t("login.form.username")}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            placeholder={t("login.form.password")}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <CustomLink to="/forgot-password" style={{ textAlign: "right" }}>
            {t("login.form.forgot_password")}
          </CustomLink>
          <Button onClick={handleClick} disabled={isFetching}>
            {isFetching ? (
              <Image src={loaderSvg} alt="loader" />
            ) : (
              t("login.form.validate")
            )}
          </Button>

          <CustomLink
            to={{
              pathname: "/register",
              state: { fromCart: true },
            }}
          >
            {t("login.form.register")}
          </CustomLink>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Login;
