import { createSlice } from "@reduxjs/toolkit";

export const rangeSlice = createSlice({
  name: "range",
  initialState: {
    ranges: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    resetRange: (state) => {
      state.isFetching = false;
      state.error = false;
    },
    //GET ALL
    getRangeStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getRangeSuccess: (state, action) => {
      state.isFetching = false;
      state.ranges = action.payload;
    },
    getRangeFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const { resetRange, getRangeStart, getRangeSuccess, getRangeFailure } =
  rangeSlice.actions;

export default rangeSlice.reducer;
