export const sliderItems = {
  en: [
    {
      image: "/slider/SLIDE 1 - RANGE.webp",
      title: "SNAPCHAT DIAMANT NOIR",
      description: "Discover our brand new BLACK DIAMOND SNAPCHAT with its milk concentrate, cream and soap",
      bg: "slider/1.webp",
      bgColor: "#838898",
      link: "/products/ranges/snapchat-diamant-noir"
    },
    {
      image: "/slider/SLIDE 2 - RANGE.webp",
      title: "GLAM'S, SELFIE AND MORE",
      description: "A unique and complete range for women",
      bg: "slider/2.webp",
      bgColor: "#FFE083",
      link: "/ranges"
    },
    {
      image: "/slider/SLIDE 3 - SELFIE.webp",
      title: "SELFIE",
      description:
        "Quickly eliminates dark spots, redness and aging lines",
      bg: "/slider/3.webp",
      bgColor: "F7F8F1",
      button: "Discover",
      link: "/products/ranges/selfie"
    },
    {
      image: "/slider/SLIDE 4 - TEINT DIAMANT.webp",
      title: "TEINT DIAMANT",
      description:
        "TEINT DIAMANT range makes your body uniform and renews your cells",
      bg: "/slider/4.webp",
      bgColor: "FCF5E7",
      button: "Discover",
      link: "/products/ranges/snapchat-diamant-noir"
    },
  ],
  fr: [
    {
      image: "/slider/SLIDE 1 - RANGE.webp",
      title: "SNAPCHAT DIAMANT NOIR",
      description: "Découvrez notre toute nouvelle SNAPCHAT DIAMANT NOIR avec son concentré lait, sa crême, et son savon",
      bg: "slider/1.webp",
      bgColor: "#838898",
      link: "/products/ranges/teint-diamant-noir"
    },
    {
      image: "/slider/SLIDE 2 - RANGE.webp",
      title: "GLAM'S, SELFIE ET PLUS",
      description: "Une gammme unique et complète pour les femmes",
      bg: "slider/2.webp",
      bgColor: "#FFE083",
      link: "/ranges"
    },
    {
      image: "/slider/SLIDE 3 - SELFIE.webp",
      title: "SELFIE",
      description:
        "Elimine rapidement les taches noires, les rougeurs et les traits de vieillissement",
      bg: "/slider/3.webp",
      bgColor: "F7F8F1",
      button: "Découvrir",
      link: "/products/ranges/selfie"
    },
    {
      image: "/slider/SLIDE 4 - TEINT DIAMANT.webp",
      title: "TEINT DIAMANT",
      description:
        "La gamme TEINT DIAMANT rend votre corps uniforme et renouvèle vos cellules",
      bg: "/slider/4.webp",
      bgColor: "FCF5E7",
      button: "Découvrir",
      link: "/products/ranges/teint-diamant"
    },
  ]
};