import styled from "styled-components";
import { mobile } from "../responsive";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 80rem;
  margin: auto;
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
`;

const Title = styled.h1`
  font-weight: bold;
  padding: 2rem;
  ${mobile({
  fontSize: "20px",
  margin: 0,
  padding: "1rem",
})}
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;
  padding: 15px;
  ${mobile({
  margin: "5px",
  padding: "5px",
})}
`;

const Subtitle = styled.h2`
  font-weight: bold;
  margin-bottom: 10px;
  padding: 10px;
  ${mobile({
  fontSize: "18px",
})}
`;

const SmallTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 10px;
  padding: 10px;
  ${mobile({
  fontSize: "16px",
})}
`;

const Text = styled.p`
  flex: 1;
  font-size: 16px;
  margin: 10px;
  padding: 10px;
  line-height: 2;
`;

const Image = styled.img`
  width: 100%;
  max-width: 30%;
  height: auto;
  max-height: 30%;
  margin: 10px;
  ${mobile({
  display: "none",
})}
`;

const CustomImage = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 80%;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  height: 20rem;
  display: none;
  ${mobile({
  margin: "5px",
  padding: "5px",
  display: "initial",
  marginBottom: "-2rem",
})}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${mobile({
  flexDirection: "column",
})}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  ${mobile({
  flexDirection: "column",
  margin: 0,
  padding: 0,
})}
`;

const AboutUs = () => {
  const sidebarState = useSelector((state) => state.sidebar.open);

  return (
    <Container sidebarState={sidebarState}>
      <Helmet>
        <title>A propos - Laboratoire Luxe Cosmétique</title>
      </Helmet>
      <Title>A propos de Laboratoire Luxe Cosmétique</Title>
      <Section>
        <Row>
          <ImageContainer>
            <CustomImage
              src="https://laboratoireluxecosmetique.com/assets/about-mobile.jpg"
              alt="about"
            />
          </ImageContainer>
          <Column>
            <Subtitle>Notre mission</Subtitle>
            <Text>
              C'est de œuvrer à toujours accroître l’expérience et la
              satisfaction de la clientèle, à créer une expérience unique, à
              assurer l’uniformité dans le déroulement des soins esthétiques et
              la recommandation des produits de soins.
            </Text>

            <Subtitle>Notre Vision</Subtitle>
            <Text>
              À cet effet, nous visons à offrir un service personnalisé et professionnel, dans l’agrément,
              avec un personnel motivé et ayant la passion pour leur profession.
            </Text>
          </Column>
          <Image
            src="https://laboratoireluxecosmetique.com/assets/about.jpeg"
            alt="about"
          />
        </Row>
      </Section>
      <Section>
        <Row>
          <ImageContainer>
            <CustomImage
              src="https://laboratoireluxecosmetique.com/assets/about-mobile-2.jpg"
              alt="about"
            />
          </ImageContainer>
          <Image
            src="https://laboratoireluxecosmetique.com/assets/about-2.jpeg"
            alt="about"
          />
          <Column>
            <Subtitle>Là où tout à commencer</Subtitle>
            <Text>
              Créé il y a plus de 09 ans à l'initiative d'un groupe de médecins
              et pharmaciens, pionnier de la cosmétique naturelle et bio, acteur
              incontournable du marché de l’homéopathie et de la phytothérapie,
              le Laboratoire Luxe Cosmetique élabore des médicaments,
              préparations pharmaceutiques et soins cosmétiques naturels selon
              une conception globale du soin, qui tient compte des
              correspondances profondes existant entre l'Être humain et la
              Nature. Le Laboratoire luxe cosmétique est fidèle depuis plus de
              09 ans à ses principes fondateurs.
            </Text>

            <Subtitle>Notre Vision</Subtitle>
            <Text>
              Nous désirons à cet effet nous démarquer par notre
              professionnalisme et notre innovation. À cet effet, nous visons à
              offrir un service personnalisé et professionnel, dans l’agrément,
              avec un personnel motivé et ayant la passion pour leur profession.
            </Text>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <SmallTitle>
              Notre laboratoire est spécialisé dans la création de produits de
              soins, avec une approche globale et un savoir-faire
            </SmallTitle>
            <Text>
              Le Laboratoire Luxe Cosmetique met à votre disposition toutes les
              compétences nécessaires à la transformation de vos projets en
              réalités et en réussites marketing
            </Text>

            <SmallTitle>Un service de qualité</SmallTitle>
            <Text>
              Notre service réglementaire d'accompagnement sur les dossiers
              produits et les différentes démarches de mise sur le marché une
              capacité de fabrication et une expertise dans les process
              industriels
            </Text>

            <SmallTitle>
              Une souplesse et une productivité pour le conditionnement de vos
              produits
            </SmallTitle>
            <Text>
              Nous avons l’expérience et l’équipe d'experts d’un laboratoire de
              production
            </Text>
            <SmallTitle>
              Nous sommes présents sur le territoire de plus de 8 pays
            </SmallTitle>
            <Text>
              Nous livrons dans plus de 30 pays, et nous continuons de nous
              développer.
            </Text>
          </Column>
        </Row>
      </Section>
    </Container>
  );
};

export default AboutUs;
