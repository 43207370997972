import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { mobile } from "../responsive";
import { useTranslation } from "react-i18next";
import { updateLanguage } from "../redux/storeRedux";
import WhatsappImg from "../assets/whatsapp.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(86%)" : "translateX(0%)"};
  ${mobile({
    fontSize: "12px",
  })}
`;

const Banner = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
  background-color: #ffe083;
  color: black;
  font-size: 14px !important;
`;

const BannerWrapper = styled.div`
  padding: 8px 8px;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: black;
  color: white;
`;

const WrapperBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  padding: 5px 10px;
  max-width: 80rem;
  font-weight: 500;
  justify-content: flex-end;
  width: 100%;
  ${mobile({
    justifyContent: "center",
  })}
`;

const Whatsapp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
`;

const WhatsappText = styled.span`
  font-size: 14px;
  font-weight: 200;
  margin: 0px 10px;
`;

const Language = styled.span`
  font-size: 14px;
  cursor: pointer;
`;

const Select = styled.select`
  width: 60px !important;
  height: 30px;
  position: relative;
  background-color: transparent;
  border: none;
  color: white;

  &:focus {
    outline: none;
  }
`;

const Option = styled.option`
  color: black;
`;
const WhatsappImage = styled.img`
  width: 25px;
  height: 25px;
`;

const Announcement = () => {
  const dispatch = useDispatch();
  const sidebarState = useSelector((state) => state.sidebar.open);
  const language = useSelector((state) => state.store.language);
  const store = useSelector((state) => state.store.stores[0]);
  const [t, i18n] = useTranslation("common");

  const openWhatsapp = () => {
    window.open("https://api.whatsapp.com/send?phone=+22966106960");
  };

  const changeLanguage = (e) => {
    dispatch(updateLanguage(e.target.value));
    i18n.changeLanguage(e.target.value);
  };

  return (
    <Container sidebarState={sidebarState}>
      <Banner>
        <BannerWrapper>{t("banner.text")}</BannerWrapper>
      </Banner>
      <Wrapper>
        <WrapperBody>
          <Whatsapp onClick={openWhatsapp}>
            <WhatsappImage src={WhatsappImg} alt="Whatsapp" />
            <WhatsappText>
              <b>{store?.phones[0]}</b>
            </WhatsappText>
          </Whatsapp>
          <Language name="language">
            <Select value={language} onChange={changeLanguage}>
              <Option value="fr">🇫🇷 Fr</Option>
              <Option value="en">🇺🇸 En</Option>
            </Select>
          </Language>
        </WrapperBody>
      </Wrapper>
    </Container>
  );
};

export default Announcement;
