import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import { register } from "../redux/apiRequests";
import { useEffect } from "react";
import { resetError } from "../redux/userRedux";
import { useTranslation } from "react-i18next";
import loaderSvg from "../assets/loader.svg";

const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
  ${mobile({ marginTop: "40px", marginBottom: "40px" })}
`;

const Wrapper = styled.div`
  width: 40%;
  padding: 20px;
  border: 1px solid #ccc;
  ${mobile({ width: "75%" })}
  ${tablet({ width: "65%" })}
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const FormGroup = styled.div`
  flex: 1;
  min-width: 40%;
  margin: 0 20px;

  ${mobile({
    margin: "0 15px",
  })}
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
`;

const Input = styled.input`
  flex: 1;
  width: 92%;
  padding: 10px;
  margin: 20px 10px 0px 0px;
  border: 1px solid #ccc;

  ${tablet({
    width: "90%",
  })}
`;

const Agreement = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin: 20px;
`;

const Button = styled.button`
  width: 40%;
  padding: 10px;
  background-color: #000;
  border: none;
  cursor: pointer;
  color: white;
  margin-top: 20px;
`;

const Span = styled.span``;

const Error = styled.span`
  color: ${({ type }) => type === "error" && "#f00"};
  font-size: 12px;
  ${mobile({ width: "75%" })}
`;

const Image = styled.img`
  width: 105%;
  height: 20px;

  ${mobile({
    width: "120%",
  })}
`;

const Register = () => {
  const sidebarState = useSelector((state) => state.sidebar.open);
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.user.isFetching);
  const isDuplicate = useSelector((state) => state.user.isDuplicate);
  const duplicateError = useSelector((state) => state.user.duplicateError);
  const [inputs, setInputs] = useState({});
  const [t, i18n] = useTranslation("common");
  const [helper, setHelper] = useState({
    firstname: {
      text: "",
      color: "",
    },
    lastname: {
      text: "",
      color: "",
    },
    username: {
      text: "",
      color: "",
    },
    email: {
      text: "",
      color: "",
    },
    password: {
      text: t("register.helpers.min_character"),
      color: "",
    },
    newpassword: {
      text: "",
      color: "",
    },
  });

  useEffect(() => {
    dispatch(resetError());
  }, [dispatch, isFetching, isDuplicate, duplicateError]);

  useEffect(() => {
    if (isDuplicate) {
      if (duplicateError === "username") {
        setHelper((prev) => {
          return {
            ...prev,
            username: {
              text: t("register.helpers.username_error"),
              color: "error",
            },
          };
        });
      } else if (duplicateError === "email") {
        setHelper((prev) => {
          return {
            ...prev,
            email: {
              text: t("register.helpers.email_error"),
              color: "error",
            },
          };
        });
      }
    }
  }, [isDuplicate, dispatch, duplicateError]);

  const resetHelpers = (e) => {
    e.target.name === "firstname" &&
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: "",
            color: "",
          },
        };
      });

    e.target.name === "lastname" &&
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: "",
            color: "",
          },
        };
      });
    e.target.name === "email" &&
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: "",
            color: "",
          },
        };
      });
    e.target.name === "password" &&
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: t("register.helpers.min_character"),
            color: "",
          },
        };
      });
    e.target.name === "newpassword" &&
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: "",
            color: "",
          },
        };
      });
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    resetHelpers(e);
  };

  const handleClick = async (e) => {
    e.preventDefault();

    inputs.firstname &&
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.firstname &&
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: t("register.helpers.firstname"),
            color: "error",
          },
        };
      });

    inputs.lastname &&
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.lastname &&
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: t("register.helpers.lastname"),
            color: "error",
          },
        };
      });

    inputs.email &&
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.email &&
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: t("register.helpers.email"),
            color: "error",
          },
        };
      });

    inputs.email &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputs.email) ===
        false &&
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: t("register.helpers.invalid_email"),
            color: "error",
          },
        };
      });

    inputs.password &&
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.password &&
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: t("register.helpers.password"),
            color: "error",
          },
        };
      });

    inputs.password &&
      inputs.password.length < 5 &&
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: t("register.helpers.min_character"),
            color: "error",
          },
        };
      });

    inputs.username &&
      setHelper((prev) => {
        return {
          ...prev,
          username: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.username &&
      setHelper((prev) => {
        return {
          ...prev,
          username: {
            text: t("register.helpers.username"),
            color: "error",
          },
        };
      });

    inputs.newpassword &&
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.newpassword &&
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: t("register.helpers.password"),
            color: "error",
          },
        };
      });

    inputs.password &&
      inputs.newpassword &&
      inputs.password !== inputs.newpassword &&
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: t("register.helpers.password_notMatch"),
            color: "error",
          },
        };
      });

    if (
      inputs.firstname &&
      inputs.lastname &&
      inputs.username &&
      inputs.email &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputs.email) &&
      inputs.password &&
      inputs.newpassword &&
      inputs.password === inputs.newpassword &&
      inputs.password.length >= 5
    ) {
      register(
        {
          firstname: inputs.firstname,
          lastname: inputs.lastname,
          username: inputs.username,
          email: inputs.email,
          password: inputs.password,
        },
        dispatch
      );
    }
  };

  return (
    <Container sidebarState={sidebarState}>
      <Helmet>
        <title>{t("register.title")} - Laboratoire Luxe Cosmétique</title>
      </Helmet>
      <Wrapper>
        <Title> {t("register.title")}</Title>
        <Form>
          <FormGroup>
            <Input
              type="text"
              name="lastname"
              placeholder={t("register.lastname")}
              onChange={handleChange}
            />
            <Error type={helper.lastname.color === "error" ? "error" : "info"}>
              {helper.lastname.text}
            </Error>
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="firstname"
              placeholder={t("register.firstname")}
              onChange={handleChange}
            />
            <Error type={helper.firstname.color === "error" ? "error" : "info"}>
              {helper.firstname.text}
            </Error>
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="username"
              placeholder={t("register.username")}
              onChange={handleChange}
            />
            <Error type={helper.username.color === "error" ? "error" : "info"}>
              {helper.username.text}
            </Error>
          </FormGroup>
          <FormGroup>
            <Input
              type="email"
              name="email"
              placeholder={t("register.email")}
              onChange={handleChange}
            />
            <Error type={helper.email.color === "error" ? "error" : "info"}>
              {helper.email.text}
            </Error>
          </FormGroup>
          <FormGroup>
            <Input
              type="password"
              name="password"
              placeholder={t("register.password")}
              minLength="5"
              onChange={handleChange}
            />
            <Error type={helper.password.color === "error" ? "error" : "info"}>
              {helper.password.text}
            </Error>
          </FormGroup>
          <FormGroup>
            <Input
              type="password"
              name="newpassword"
              placeholder={t("register.confirmPassword")}
              minLength="5"
              onChange={handleChange}
            />
            <Error
              type={helper.newpassword.color === "error" ? "error" : "info"}
            >
              {helper.newpassword.text}
            </Error>
          </FormGroup>
          <Agreement>
            <Span>
              {t("register.agreement")} (
              <Link style={{ color: "#000" }} to="/privacy-policy">
                {t("register.read")}
              </Link>
              )
            </Span>

            <Button onClick={handleClick} disabled={isFetching}>
              {isFetching ? (
                <Image src={loaderSvg} alt="loader" />
              ) : (
                t("register.create")
              )}
            </Button>
          </Agreement>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Register;
