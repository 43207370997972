import styled from "styled-components";
import RangeItem from "./RangeItem";
import { mobile } from "../responsive";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding: 20px;
  max-width: 80rem;
  margin: auto;
  ${mobile({ padding: "0px", flexDirection: "column", margin: "5px" })}
`;

const Title = styled.h1`
  font-weight: bold;
  padding: 2rem 2rem 1rem 2rem;
  text-align: center;
  margin-bottom: 2rem;
  ${mobile({
  fontSize: "20px",
  margin: 0,
  padding: "1rem",
})}
`;

const RangesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 80rem;
  margin: auto;
  ${mobile({ padding: "0px", flexDirection: "column", flexWrap: "nowrap" })}
`;

const Ranges = ({ displayHome }) => {
  const ranges = useSelector((state) => state.range.ranges);
  const [t, i18n] = useTranslation("common");

  return (
    <Container>
      {!displayHome && <Title>
        {t("range.title")}
      </Title>}
      {displayHome && <Title>
        {t("range.alter_title")}
      </Title>}
      <RangesContainer>
        {ranges.slice(0, displayHome).map((range) => (
          <RangeItem range={range} key={range._id} />
        ))}
      </RangesContainer>
    </Container>
  );
};

export default Ranges;
