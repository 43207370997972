import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import Layout from './templates/Layout'
import Home from './pages/Home'
import ProductList from './pages/ProductList'
import Product from './pages/Product'
import Cart from './pages/Cart'
import Login from './pages/Login'
import Register from './pages/Register'
import NotFound from './pages/404'
import { useSelector } from 'react-redux'
import AboutUs from './pages/About'
import Contact from './pages/Contact'
import ScrollToTop from './utils/ScrollToTop'
import GeneralConditions from './pages/Terms&Conditions'
import Copyright from './pages/Copyright'
import Delivery from './pages/Delivery'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Cookie from './components/Cookie'
import ConfirmSubscription from './pages/ConfirmSubscription'
import RangeList from './pages/RangeList'
import Account from './pages/Account'
import i18next from 'i18next'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'

import ForgotPassword from './pages/ForgotPasword'
const dotenv = require('dotenv')

dotenv.config()

const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: process.env.NODE_ENV === 'development' ? true : false, // enable logs
}

const App = () => {
  const user = useSelector((state) => state.user.currentUser)
  const language = useSelector((state) => state.store.language)

  i18next.changeLanguage(language)

  ReactGA.initialize([
    {
      trackingId: process.env.REACT_APP_GA_ID,
      testMode: process.env.NODE_ENV === 'development' ? true : false,
    },
  ])

  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, advancedMatching, options)

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/confirm-subscription">
          <ConfirmSubscription />
        </Route>
        <Layout>
          <Switch>
            <Route path="/404">
              <NotFound />
            </Route>
            <Route exact path="/myaccount">
              <Account />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/home/cm">
              <Home />
            </Route>
            <Route path="/cm">
              <Redirect to="/home/cm" />
            </Route>
            <Route path="/products">
              <ProductList />
            </Route>
            <Route path="/products/:category">
              <ProductList />
            </Route>
            <Route path="/product/:id">
              <Product />
            </Route>
            <Route path="/cart">
              <Cart />
            </Route>
            <Route path="/wishlist">
              <Cart />
            </Route>
            <Route path="/about">
              <AboutUs />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/copyright">
              <Copyright />
            </Route>
            <Route path="/terms-and-conditions">
              <GeneralConditions />
            </Route>
            <Route path="/delivery">
              <Delivery />
            </Route>
            <Route path="/ranges">
              <RangeList />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/login">
              {user && user.role === 'user' ? <Redirect to="/" /> : <Login />}
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/reset-password/:token">
              <ForgotPassword />
            </Route>
            <Route path="/register">
              {user && user.role === 'user' ? (
                <Redirect to="/" />
              ) : (
                <Register />
              )}
            </Route>
            <Route path="**">
              <NotFound />
            </Route>
          </Switch>
          <Cookie />
        </Layout>
      </Switch>
    </Router>
  )
}

export default App
