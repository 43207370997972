import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile, touch, tablet } from "../responsive";

const Container = styled.div`
  margin: 3px;
  height: 52vh;
  position: relative;
  margin: 10px;
  width: calc(33% - 16px);
  ${touch({ width: "calc(50% - 16px)", height: "calc(80vh - 16px)" })};
  ${tablet({ width: "calc(46% - 1px)", height: "calc(50vh - 16px)" })};
  ${mobile({ width: "100%", height: "70vh", margin: 0, marginBottom: "20px" })};
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${mobile({ height: "100%" })}
  transition: transform 1s;
  transform: ${(props) => (props.active ? "scale(1.04)" : "scale(1)")};
`;

const Info = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.h1`
  color: white;
  margin-bottom: 20px;
  text-align: center;
`;
const Button = styled.div`
  border: none;
  padding: 10px;
  background: white;
  color: gray;
  cursor: pointer;
  font-weight: 600;
`;

const RangeItem = ({ range }) => {
  const [t, i18n] = useTranslation("common");
  const language = useSelector((state) => state.store.language);
  const [active, setActive] = useState(false);

  const encoreURI = (asset_url) => {
    // encode ( by %)
    return encodeURI(asset_url);
  };

  return (
    <Container
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <Link to={`/products/ranges/${range.slug}`}>
        <Image
          active={active}
          src={`${process.env.REACT_APP_ASSETS_URL}/products/${encoreURI(
            range.image
          )}`}
        />
        <Info>
          <Title>{language === "fr" ? range.name : range.en.name}</Title>
          <Button>{t("range.view")}</Button>
        </Info>
      </Link>
    </Container>
  );
};

export default RangeItem;
