import { useEffect, useState } from "react";
import styled from "styled-components";
import Product from "./Product";
import axios from "axios";
import { publicRequest } from "../requestMethods";
import { mobile } from "../responsive";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Loading, Pagination } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding: 20px;
  max-width: 80rem;
  margin: 2rem auto;
  background-color: #fff;
`;

const Title = styled.h1`
  font-weight: bold;
  padding: 2rem 2rem 1rem 2rem;
  text-align: center;
  ${mobile({
    fontSize: "20px",
    margin: 0,
    padding: "1rem",
  })}
`;

const Separator = styled.hr`
  max-width: 20rem;
  margin: auto;
  border: 0;
  height: 1px;
  margin-bottom: 3rem;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
`;

const ProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  ${mobile({
    justifyContent: "flex-start",
    flexWrap: "wrap",
    margin: "0 auto",
    padding: "0",
  })}
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  margin-top: 3rem;
`;

const SubTitle = styled.h2`
  font-weight: bold;
  padding: 2rem 2rem 1rem 2rem;
`;

const Products = ({ type, range, cat, filters, sort }) => {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(0);
  const search = new URLSearchParams(location.search);
  const [t, i18n] = useTranslation("common");

  useEffect(async () => {
    if (search.get("q")) {
      try {
        const res = await publicRequest.get(
          `/products/search?q=${search.get("q")}`
        );
        setProducts(res.data);
        setLoading(false);
      } catch (err) {}
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const queryParams = {};
    queryParams["page"] = page; //Page Number

    if (range && cat == "ranges") {
      const getCategory = async () => {
        try {
          const res = await publicRequest.get(
            `/ranges/slug/${range}?page=${queryParams.page}?sort=${sort}`
          );
          const datalytics = await publicRequest.get(
            `/ranges/${res.data._id}/analytics`
          );
          setCount(datalytics.data);
          if (datalytics.data % 10 === 0)
            setTotalPages(Math.floor(datalytics.data / 10));
          else setTotalPages(Math.floor(datalytics.data / 10) + 1);

          setCategory(JSON.stringify(res.data));
          setProducts(res.data.products);
          setLoading(false);
          if (res.data.products.length !== 0 && page !== 1) {
            window.scrollTo(0, 240);
          }
        } catch (err) {}
      };
      getCategory();
    } else if (!range && cat) {
      const getCategory = async () => {
        try {
          const res = await publicRequest.get(
            `/categories/slug/${cat}?page=${queryParams.page}?sort=${sort}`
          );
          const datalytics = await publicRequest.get(
            `/categories/${res.data._id}/analytics`
          );
          setCount(datalytics.data);
          if (datalytics.data % 10 === 0)
            setTotalPages(Math.floor(datalytics.data / 10));
          else setTotalPages(Math.floor(datalytics.data / 10) + 1);

          setCategory(JSON.stringify(res.data));
          setProducts(res.data.products);
          setLoading(false);
          if (res.data.products.length !== 0 && page !== 1) {
            window.scrollTo(0, 240);
          }
        } catch (err) {}
      };
      getCategory();
    } else {
      const getProducts = async () => {
        const queryParams = {};
        queryParams["page"] = page; //Page Number

        try {
          if (!type) {
            const res = await publicRequest.get(
              `/products?page=${queryParams.page}`
            );
            setLoading(false);
            setProducts(res.data);
          } else {
            if (type === "discover") {
              const res = await publicRequest.get(
                `/categories/slug/${`discover...wldkat`}`
              );
              setCategory(JSON.stringify(res.data));
              setProducts(res.data.products);
            }
            if (type === "bestSeller") {
              const res = await publicRequest.get(
                `/categories/slug/${`best-sellers`}?sort=${sort}`
              );
              setCategory(JSON.stringify(res.data));
              setProducts(res.data.products);
            }
            if (type === "new") {
              const res = await publicRequest.get(
                `/categories/slug/${`what's-new`}?sort=${sort}`
              );
              setCategory(JSON.stringify(res.data));
              setProducts(res.data.products);
            }
          }
        } catch (err) {
          setLoading(false);
        }
      };

      const getProductsCount = async () => {
        try {
          if (!type) {
            const res = await publicRequest.get(`/products/analytics`);
            setCount(res.data);
            if (res.data % 10 === 0) setTotalPages(Math.floor(res.data / 10));
            else setTotalPages(Math.floor(res.data / 10) + 1);
          }
        } catch (err) {}
      };
      getProducts();
      getProductsCount();
    }
  }, [cat, range, type, page]);

  useEffect(() => {
    /*     if (filteredProducts.length !== 0 && filters["range"] === "Gamme") {
          setTotalPages(0);
        } else if (filteredProducts.length === 0 && filters["range"] === "Gamme") {
          setPage(1);
        } */
    ((cat && JSON.parse(category)?._id) || (!cat && !range && !type)) &&
      setFilteredProducts(
        products.filter((item) =>
          Object.entries(filters).every(([key, value]) => {
            if (key === "price") {
              const [min, max] = value.split("-");
              return item[key] >= parseInt(min) && item[key] <= parseInt(max);
            } else if (value === "Gamme") {
              return item;
            }
            if ((cat && range) || (cat && !range)) {
              return item[key] === value;
            } else {
              return item.range._id === value;
            }
          })
        )
      );
  }, [products, category, filters]);

  useEffect(() => {
    if (sort === "asc") {
      !search.get("q") &&
        setFilteredProducts((prev) =>
          [...prev].sort(
            (a, b) =>
              new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
          )
        );
      search.get("q") &&
        setProducts((prev) =>
          [...prev].sort(
            (a, b) =>
              new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
          )
        );
    } else {
      !search.get("q") &&
        setFilteredProducts((prev) =>
          [...prev].sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          )
        );
      search.get("q") &&
        setProducts((prev) =>
          [...prev].sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          )
        );
    }
  }, [sort, products, category, filters]);

  const isRange = () => {
    return (
      filters &&
      Object.entries(filters).every(([key, value]) => {
        if (value === "Gamme") {
          return false;
        }
        return true;
      })
    );
  };

  useEffect(() => {
    return () => {
      setProducts([]);
      setFilteredProducts([]);
      setCategory(null);
      setPage(1);
      setTotalPages(0);
      setCount(0);
    };
  }, [cat, range, type]);

  return (
    <Container>
      {cat && <Title>{JSON.parse(category)?.name}</Title>}
      {(cat === undefined || cat === "" || cat !== "search") &&
        type === "new" && <Title>{t("products.new_products")}</Title>}
      {(cat === undefined || cat === "" || cat !== "search") &&
        type === "discover" && <Title>Découvrez...WLDKAT</Title>}
      {(cat === undefined || cat === "" || cat !== "search") &&
        type === "bestSeller" && <Title>{t("products.best_seller")}</Title>}
      {(cat === undefined || cat === "") &&
        cat !== "search" &&
        !type &&
        !range && <Title>{t("products.all_products")}</Title>}
      {cat === "search" && !type && search.get("q") && (
        <SubTitle>
          {t("products.search_results")} {search.get("q")}
        </SubTitle>
      )}
      {cat !== "search" && <Separator></Separator>}
      {((loading && !type && page === 1) ||
        (loading &&
          !cat &&
          !range &&
          filteredProducts.length === 0 &&
          !type &&
          page === 1 &&
          location.pathname === "/products")) && (
        <LoadingWrapper>
          <Loading color="warning" size="xl" type="gradient" />
        </LoadingWrapper>
      )}
      <ProductsContainer>
        {!loading &&
          cat !== "ranges" &&
          products.length === 0 &&
          location.pathname !== "/" && (
            <Empty>{t("products.empty_category")}</Empty>
          )}
        {!loading && cat === "ranges" && isRange() && products.length === 0 && (
          <Empty>{t("products.empty_range")}</Empty>
        )}
        {(!loading &&
          cat === "ranges" &&
          filteredProducts.length === 0 &&
          !search.get("q") &&
          isRange()) ||
          (!loading &&
            cat !== "ranges" &&
            !range &&
            filteredProducts.length === 0 &&
            !search.get("q") &&
            isRange() &&
            products.length !== 0 && (
              <Empty>{t("products.product_not_found")}</Empty>
            ))}

        {(!loading && cat && category) || (!loading && !cat && !range && !type)
          ? filteredProducts.map((item) => (
              <Product type={type} item={item} key={item._id} />
            ))
          : products.map((item) => (
              <Product type={type} item={item} key={item._id} />
            ))}
      </ProductsContainer>

      {!type && count > 10 && (
        <PaginationWrapper>
          <Pagination
            color="warning"
            total={totalPages}
            initialPage={page}
            onChange={(e) => setPage(e)}
          />
        </PaginationWrapper>
      )}
    </Container>
  );
};

export default Products;
