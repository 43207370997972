import {
  loginFailure,
  loginStart,
  loginSuccess,
  registerFailure,
  registerStart,
  registerSuccess,
  getUserExpiredFailure,
  getUserFailure,
  getUserStart,
  getUserSuccess,
  userEmailDuplicateFailure,
  userNameDuplicateFailure,
  updateUserSuccess,
  updateUserFailure,
  updateUserExpiredFailure,
  updateUserStart,
} from "./userRedux";
import { publicRequest, BASE_URL, userRequest } from "../requestMethods";
import { getStoreFailure, getStoreStart, getStoreSuccess } from "./storeRedux";
import { getRangeFailure, getRangeStart, getRangeSuccess } from "./rangeRedux";
import {
  getCategoryFailure,
  getCategoryStart,
  getCategorySuccess,
} from "./categoryRedux";
import {
  getProductFailure,
  getProductStart,
  getProductSuccess,
} from "./productRedux";
import { addToCartFailure, addToCartStart, addToCartSuccess, getCartFailure, getCartStart, getCartSuccess, resetCart, updateCartFailure, updateCartStart, updateCartSuccess } from "./cartRedux";
import { createOrderFailure, createOrderStart, createOrderSuccess, getOrderExpiredFailure, getOrderFailure, getOrderStart, getOrderSuccess } from "./orderRedux";

export const login = async (dispatch, userpassword, user) => {

  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", userpassword);
    if (res.data.wishlist.length === 0) {
      res.data.wishlist = user?.wishlist;
      await updateUser(res.data._id, { wishlist: user?.wishlist.map(product => product._id) }, dispatch);
    }
    dispatch(loginSuccess(res.data));
    location.reload();
  } catch (err) {
    dispatch(loginFailure());
  }
};

export const subscribe = async (email, email_confirmed) => {
  var axios = require("axios");
  var data = JSON.stringify({
    email: email,
  });
  var config = {};
  if (email_confirmed === true) {
    config = {
      method: "post",
      url: `${BASE_URL}/mailjet/newsletter/subscribe?email_confirmed=true`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
  } else {
    config = {
      method: "post",
      url: `${BASE_URL}/mailjet/newsletter/subscribe`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
  }
  return axios(config);
};

export const send = async (name, email, subject, message) => {
  var axios = require("axios");
  var data = JSON.stringify({
    name: name,
    email: email,
    subject: subject,
    message: message,
  });

  var config = {
    method: "post",
    url: `${BASE_URL}/mailjet`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
};

export const getStore = async (dispatch) => {
  dispatch(getStoreStart());
  try {
    const res = await publicRequest.get("/store");
    dispatch(getStoreSuccess(res.data));
  } catch (err) {
    dispatch(getStoreFailure());
  }
};

export const getRanges = async (dispatch) => {
  dispatch(getRangeStart());
  try {
    const res = await publicRequest.get("/ranges");
    dispatch(getRangeSuccess(res.data));
  } catch (err) {
    dispatch(getRangeFailure());
  }
};

export const getCategories = async (dispatch) => {
  dispatch(getCategoryStart());
  try {
    const res = await publicRequest.get("/categories");
    dispatch(getCategorySuccess(res.data));
  } catch (err) {
    dispatch(getCategoryFailure());
  }
};

export const getProducts = async (dispatch) => {
  dispatch(getProductStart());
  try {
    const res = await publicRequest.get("/products");
    dispatch(getProductSuccess(res.data));
  } catch (err) {
    dispatch(getProductFailure());
  }
};

export const register = async (user, dispatch) => {
  dispatch(registerStart());
  try {
    const res = await publicRequest.post("/auth/register", user);
    dispatch(registerSuccess(res.data));
  } catch (err) {
    if (err?.response?.data?.message?.includes("username")) {
      dispatch(userNameDuplicateFailure());
    } else if (err?.response?.data?.message?.includes("email")) {
      dispatch(userEmailDuplicateFailure());
    } else {
      dispatch(registerFailure());
    }
  }
};

export const getUser = async (id, dispatch) => {
  dispatch(getUserStart());
  try {
    const res = await userRequest.get(`/users/user/find/${id}`);
    dispatch(getUserSuccess(res.data));
  } catch (err) {
    if (err.message.includes("403")) {
      dispatch(getUserExpiredFailure());
    } else {
      dispatch(getUserFailure());
    }
  }
};

export const updateUser = async (id, user, dispatch) => {
  dispatch(updateUserStart());
  try {
    const res = await userRequest.put(`/users/user/${id}`, user);
    dispatch(updateUserSuccess(res.data));
  } catch (err) {
    if (err.message.includes("403")) {
      dispatch(updateUserExpiredFailure());
    } else {
      dispatch(updateUserFailure());
    }
  }
}

export const addCart = async (userId, cart, dispatch) => {
  dispatch(addToCartStart());
  try {
    const res = await userRequest.post(`/carts`, {
      userId: userId,
      products: cart,
    });
    dispatch(addToCartSuccess(res.data));
  } catch (err) {
    dispatch(addToCartFailure());
  }
};

export const getCart = async (id, dispatch) => {
  dispatch(getCartStart());
  try {
    const res = await userRequest.get(`/carts/find/${id}`);
    if (res.data === null) {
      await addCart(id, [], dispatch);
    }
    dispatch(getCartSuccess(res.data));
  }
  catch (err) {
    dispatch(getCartFailure());
  }
}

export const updateCart = async (id, cart, dispatch) => {
  dispatch(updateCartStart());
  try {
    const res = await userRequest.put(`/carts/${id}`, {
      products: cart,
    });
    dispatch(updateCartSuccess(res.data));
  } catch (err) {
    dispatch(updateCartFailure());
  }
}

export const createOrder = async (cartId, order, dispatch) => {
  dispatch(createOrderStart());
  try {
    const res = await userRequest.post(`/orders`, order);
    updateCart(cartId, [], dispatch);
    dispatch(createOrderSuccess(res.data));
    dispatch(resetCart());
    //dispatch(updateUser(order.userId, { wishlist: [] }, dispatch));
  } catch (err) {
    dispatch(createOrderFailure());
  }
}
