import styled from "styled-components";
import { useEffect, useState } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import { mobile, tablet } from "../responsive";
import { useDispatch, useSelector } from "react-redux";
import { addProduct, deleteProduct } from "../redux/cartRedux";
import { updateCart, updateUser } from "../redux/apiRequests";
import { addToWishlist, removeFromWishlist } from "../redux/userRedux";
import { useTranslation } from "react-i18next";

const Info = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;  /* if (product.countInStock !== 0) {
    } */
`;

const Container = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  color: #333a40;
  line-height: 1.4em;
  font-weight: normal;
  position: relative;
  width: 20%;
  margin-bottom: 0.666em;

  ${mobile({
  width: "100% !important",
})}
${tablet({
  width: "33%",
})}
`;

const ProductContainer = styled.div`
  border: none;
  margin: 20px auto 0.5em !important;
  max-width: 86% !important;
  height: 280px;
  min-height: 190px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

const Image = styled.img`
  z-index: 2;
  aspect-ratio: 0.8;
  width: 200px;
  height: 200px;
`;

const FavoriteIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  background: ${(props) => (props.isInWishlist ? "#ffc107" : "transparent")};
  cursor: pointer;
  margin-left: auto;

  &:hover {
    background-color: black;
    color: white;
  }
`;

const Title = styled.h5`
  margin: 5px;
  font-size: 0.8rem;
  margin: 10px;
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 0;
`;

const NewTagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 0;
  margin-left: auto;
`;

const NewTag = styled.div`
  background-color: #ffe083;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.5rem;
  margin-right: 5px;
`;

//fullwidth black button
const AddToCart = styled.button`
  height: 40px;
  border: none;
  background-color: black;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s ease;
  margin: 5px;
  bottom: 0;
  padding: 0 1rem;
  font-size: 11.5px;
  text-transform: uppercase;
`;

const Product = ({ type, item }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isHover, setIsHover] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const cart = useSelector((state) => state.cart);
  const [t, i18n] = useTranslation("common");

  const encoreURI = (asset_url) => {
    // encode ( by %)
    return encodeURI(asset_url);
  };

  const handleClick = () => {
    const userCart = cart;

    dispatch(addProduct({ ...item }));
    // get user cart in const

    // check if product is in cart
    const isInCart = userCart?.products?.some((product) => product._id === item._id);

    if (isInCart) {
      dispatch(deleteProduct(item));
      if (user && user._id) {
        const newCart = userCart.products.filter((product) => product._id !== item._id);
        updateCart(cart._id, newCart.map((product) => {
          return {
            productId: product._id,
          }
        }),
          dispatch);
      }
    } else {
      dispatch(addProduct(item));
      // if product is not in wishlist, add it
      const newCart = [...userCart.products, item]
      if (user && user._id) {
        updateCart(cart._id, newCart.map((product) => {
          return {
            productId: product._id,
          }
        }),
          dispatch);
      }
    };
  };

  const openProduct = () => {
    history.push(`/product/${item.slug}`);
  };

  const addToFavorite = () => {
    // get user wishlist in const
    const wishlist = user?.wishlist;

    // if product is in wishlist, remove it
    if (isInWishlist()) {
      dispatch(removeFromWishlist(item))
      if (user?._id && wishlist) {
        const newWishlist = wishlist.filter((product) => product._id !== item._id);
        updateUser(user._id, { wishlist: newWishlist.map(product => product._id) }, dispatch);
      }
    } else {
      dispatch(addToWishlist(item));
      if (user?._id && wishlist) {
        // if product is not in wishlist, add it
        const newWishlist = [...wishlist, item];
        updateUser(user._id, { wishlist: newWishlist.map(product => product._id) }, dispatch);
      }
    }
  };

  // check if product is in wishlist
  const isInWishlist = () => {
    return user?.wishlist?.some((product) => product._id === item._id);
  };

  return (
    <Container>
      <Link
        style={{ textDecoration: "none", color: "initial" }}
        to={isHover ? "#" : `/product/${item.slug}`}
      >
        <ProductContainer>
          <NewTagWrapper onClick={openProduct}>
            {type && type === "new" && <NewTag>
              {t("product.new")}
            </NewTag>}
          </NewTagWrapper>
          {item.images && (
            <Image onClick={openProduct}
              src={`${process.env.REACT_APP_ASSETS_URL}/products/${encoreURI(
                item.images[0]
              )}`}
            />
          )}
          <FavoriteIcon isInWishlist={isInWishlist()}>
            <AiOutlineHeart
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              onClick={addToFavorite}
              style={{ fontSize: "20px" }} />
          </FavoriteIcon>
        </ProductContainer>
      </Link>
      <Link
        style={{ textDecoration: "none", color: "initial" }}
        to={`/product/${item.slug}`}
      >
        <Title onClick={openProduct}>{item.name}</Title>
      </Link>
      <Bottom>
        <AddToCart onClick={handleClick}>
          {t("product.add_to_cart")}
        </AddToCart>
      </Bottom>
    </Container>
  );
};

export default Product;
