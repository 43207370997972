import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: {
      wishlist: [],
      orders: []
    },
    isFetching: false,
    error: false,
    isDuplicate: false,
    duplicateError: null,
    status: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = false;
      state.isDuplicate = false;
      state.duplicateError = null;
    },
    resetUser: (state) => {
      state.currentUser = {
        wishlist: [],
      };
      state.isFetching = false;
      state.error = false;
      state.role = null;
      state.status = null;
    },
    loginStart: (state) => {
      state.isFetching = true;
      state.isExpired = false;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.error = action.payload.role !== "user" ? true : false;
    },
    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logout: (state) => {
      state.currentUser = {
        wishlist: [],
        orders: []
      };
    },
    registerStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    registerSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
    },
    registerFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    userEmailDuplicateFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.isDuplicate = true;
      state.duplicateError = "email";
    },
    userNameDuplicateFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.isDuplicate = true;
      state.duplicateError = "username";
    },
    resetUserSession: (state) => {
      state.status = null;
    },
    getUserStart: (state) => {
      state.isFetching = true;
    },
    getUserSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = {
        ...state.currentUser,
        ...action.payload,
      };
      state.role = action.payload.user;
    },
    getUserFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.role = null;
    },
    getUserExpiredFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.status = "403";
      resetUser();
      resetUserSession();
    },
    updateUserStart: (state) => {
      state.isFetching = true;
    },
    updateUserSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = {
        ...state.currentUser,
        ...action.payload,
      };
      state.role = action.payload.user;
    },
    updateUserExpiredFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.status = "403";
      resetUser();
      resetUserSession();
    },
    updateUserFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.role = null;
    },
    addToWishlist: (state, action) => {
      const newProduct = action.payload;
      // find if product already exists in cart
      const isExistingProduct = state?.currentUser?.wishlist?.find((product) => {
        return (
          product._id ===
          newProduct._id
        );
      });
      if (!isExistingProduct) {
        // if product does not exist, add product to cart
        state?.currentUser?.wishlist?.push({ ...newProduct });
      }
    },
    removeFromWishlist: (state, action) => {
      const product = action.payload;
      const productIndex = state?.currentUser?.wishlist?.findIndex(
        (productInCart) => productInCart._id === product._id
      );
      state.currentUser.wishlist.splice(productIndex, 1);
    },
    getOrderSuccess: (state, action) => {
      state.currentUser.orders = action.payload;
    },
    getOrderExpiredFailure: (state) => {
      resetUserSession();
    }
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  resetError,
  resetUser,
  resetUserSession,
  registerStart,
  registerFailure,
  registerSuccess,
  userEmailDuplicateFailure,
  userNameDuplicateFailure,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  updateUserExpiredFailure,
  getUserFailure,
  getUserStart,
  getUserSuccess,
  getUserExpiredFailure,
  addToWishlist,
  removeFromWishlist,
  getOrderSuccess,
  getOrderExpiredFailure
} = userSlice.actions;
export default userSlice.reducer;
