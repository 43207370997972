import styled from "styled-components";
import { MdAdd, MdRemove } from "react-icons/md";
import { mobile } from "../responsive";
import { useLocation } from "react-router";
import { publicRequest } from "../requestMethods";
import { useEffect, useState } from "react";
import { addProduct, deleteProduct } from "../redux/cartRedux";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import { updateCart } from "../redux/apiRequests";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
`;

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  ${mobile({ padding: "10px", flexDirection: "column" })}
`;

const ImageContainer = styled.div`
  flex: 1;
  text-align: center;
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 0px 50px;
  ${mobile({ padding: "10px" })}
`;

const Image = styled.img`
  aspect-ratio: 1.1;
  width: 350px;
  height: 350px;
  background-color: white;
`;

const Title = styled.h1`
  font-weight: 200;
  ${mobile({ fontSize: "1.5rem" })}
`;

const AddContainer = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-between;
  ${mobile({ width: "100%" })}
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const Quantity = styled.option`
  width: 30px;
  height: 30px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  margin: 0px 5px;
  display: flex;
`;

const AddToCart = styled.button`
  background-color: white;
  padding: 10px;
  cursor: pointer;
  border: ${(props) =>
    props.countInStock == 0 ? "2px solid lightgray" : "2px solid #000"};
  color: ${(props) => (props.countInStock == 0 ? "lightgray" : "black")};

  &:hover {
    background-color: ${(props) =>
    props.countInStock == 0 ? "white" : "#000"};
    color: ${(props) => (props.countInStock == 0 ? "lightgray" : "white")};
  }
`;

const ShortDescription = styled.div`
  margin: 20px 0px;
  line-height: 2;
  ${mobile({ fontSize: "0.8rem" })}
`;

const InStock = styled.span`
  font-size: 0.8rem;
  color: ${(props) => props.countInStock < 10 && "red"};
`;

const Brand = styled.div`
  font-weight: 200;
  font-size: 0.8rem;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  margin: 10px;
  border: 1px solid #dedede;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    border: 1px solid #aaa;
  }
`;

const NewTagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  bottom: 0;
  margin-left: auto;
`;

const NewTag = styled.div`
  background-color: #ffe083;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 0.5rem;
  margin-right: 5px;
`;

const Product = () => {
  const sidebarState = useSelector((state) => state.sidebar.open);
  const location = useLocation();
  const slug = location.pathname.split("/")[2];
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [currentImage, setCurrentImage] = useState(0);
  const user = useSelector((state) => state.user);
  const [t, i18n] = useTranslation("common");

  useEffect(() => {
    window.scrollTo(0, 0);
    const getProduct = async () => {
      try {
        const res = await publicRequest.get("/products/slug/" + slug);
        const product = res.data;
        setProduct(product);
      } catch { }
    };
    getProduct();
  }, [slug, location]);

  const handleQuantity = (type) => {
    if (type === "add") {
      setQuantity(quantity + 1);
    } else {
      quantity > 1 && setQuantity(quantity - 1);
    }
  };

  const handleClick = () => {
    const userCart = cart;

    dispatch(addProduct({ ...product }));
    // get user cart in const

    // check if product is in cart
    const isInCart = userCart?.products?.some((newProduct) => newProduct._id === product._id);

    if (!isInCart) {
      dispatch(addProduct(product));
      // if product is not in wishlist, add it
      const newCart = [...userCart.products, product]
      if (user && user._id) {
        updateCart(cart._id, newCart.map((newProduct) => {
          return {
            productId: newProduct._id,
          }
        }),
          dispatch);
      }
    }
  };

  const encoreURI = (asset_url) => {
    // encode ( by %)
    return encodeURI(asset_url);
  };

  const renderProduct = () => {
    const description = EditorState.createWithContent(
      convertFromRaw(JSON.parse(product.description)));
    const blocks = description.getCurrentContent().getBlocksAsArray();
    const meta = blocks?.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    // max meta length is 200
    const metaLength = meta.length > 200 ? 200 : meta.length;
    const metaText = meta.substring(0, metaLength);

    const keywords = product.name.split(" ").join(",");

    document.getElementsByTagName('meta')["keywords"].content = keywords;
    document.getElementsByTagName('meta')["description"].content = metaText;

    return description;
  };

  // checl if product categories has new tag
  const hasNewTag = product?.categories?.some((category) => category.slug === "what's-new");

  return (
    <Container sidebarState={sidebarState}>
      <Helmet>
        <title>
          {product?.name + " - Laboratoire Luxe Cosmétique"}
        </title>
        {/*<meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content="@user" />
        <meta name="twitter:title" content="Pets - Products" />
        <meta name="twitter:description" content="Best Products for your pet" />
        <meta name="twitter:image" content="url_to_image" />*/}
        <meta property="og:title" content={product?.name} />

        <meta property="og:url" content={`${process.env.REACT_APP_ASSETS_URL}`} />
        <meta property="og:site_name" content="Laboratoire Luxe Cosmétique" />
        {/* <meta property="og:image" content={`${process.env.REACT_APP_ASSETS_URL}/products/${encoreURI(product.images[0])}`} /> */}
        <meta property="og:locale" content="fr_Fr" />
        <meta property="og:type" content="og:product" />
        <meta property="product:plural_title" content={product?.name} />
        {/* <meta property="fb:app_id" content="ID_APP_FACEBOOK" />*/}
      </Helmet>
      <Wrapper>
        <ImageContainer>
          {product?.images && (
            <Image loading="lazy"
              src={`${process.env.REACT_APP_ASSETS_URL}/products/${encoreURI(
                product.images[currentImage]
              )}`}
              alt={product.name}
              title={product.name}
            />
          )}
          <ImagesContainer>
            {product?.images &&
              product.images.map((image, index) => (
                <ImagePreview
                  key={index}
                  src={`${process.env.REACT_APP_ASSETS_URL
                    }/products/${encoreURI(image)}`}
                  alt={product.name}
                  title={product.name}
                  onClick={() => setCurrentImage(index)}
                />
              ))}
          </ImagesContainer>
        </ImageContainer>
        <InfoContainer>
          <NewTagWrapper>
            {product?.categories && hasNewTag && (
              <NewTag>{t("product_view.new")}</NewTag>
            )}
          </NewTagWrapper>
          <Brand>{product?.range?.name}</Brand>
          <Title>{product?.name}</Title>
          <ShortDescription>
            {product?.description && (
              <Editor editorState={renderProduct()} readOnly={true} />
            )}
          </ShortDescription>
          <AddContainer>
            {/*             <QuantityContainer>
              <MdRemove
                style={{ fontSize: 24 }}
                onClick={() => handleQuantity("remove")}
              />
              <Quantity>{quantity}</Quantity>
              <MdAdd
                style={{ fontSize: 24 }}
                onClick={() => handleQuantity("add")}
              />
            </QuantityContainer> */}
            <AddToCart onClick={handleClick}>{t("product_view.addCart")}</AddToCart>
          </AddContainer>
        </InfoContainer>
      </Wrapper>
    </Container>
  );
};

export default Product;
