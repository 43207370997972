import styled from "styled-components";
import Slideshow from "../components/Slideshow";
import Ranges from "../components/Ranges";
import Products from "../components/Products";
import { useSelector } from "react-redux";

const Container = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
`;

const Home = () => {
  const sidebarState = useSelector((state) => state.sidebar.open);

  return (
    <Container sidebarState={sidebarState}>
      <Slideshow />
      <Products type="new" sort="newest" />
      {/* <Products type="discover" /> */}
      <Products type="bestSeller" sort="newest" />
      <Ranges displayHome="3" />
    </Container>
  );
};

export default Home;
