import styled from "styled-components";
import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";
import { RiMapPin2Fill, RiPhoneFill, RiMailFill } from "react-icons/ri";
import paymentMethods from "../assets/payment.png";
import { mobile, tablet, touch } from "../responsive";
import { Link } from "react-router-dom";
import { AiOutlineSend } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Ring from "../@components/spinner";
import { getStore, subscribe } from "../redux/apiRequests";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import WhatsappImg from "../assets/whatsapp.png";

const Container = styled.div`
  width: auto;
  background-color: white;
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(86%)" : "translateX(0%)"};
`;

const Wrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  max-width: 80rem;
  margin: auto;
  ${mobile({ flexDirection: "column", padding: "0px" })}
  ${touch({ flexWrap: "wrap" })}
`;

const Logo = styled.h2``;

const Description = styled.p`
  margin: 20px 0;
  line-height: 1.6;
`;

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  padding: 20px;
  flex-direction: column;
  ${tablet({ flex: "30% !important" })}
  ${touch({ flex: "40%" })}
`;

const CenterLeft = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ display: "none" })}
  ${touch({ flex: "40%" })}
`;

const CenterRight = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ display: "none" })}
  ${tablet({ flex: "30% !important" })}
    ${touch({ flex: "40%" })}
`;

const Right = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ marginTop: "-2rem" })}
  ${tablet({ flex: "40%" })}
  ${touch({ flex: "40%" })}
`;

const SocialContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding: 0 20px;
  margin-top: 1rem;
`;

const Title = styled.h3`
  margin-bottom: 30px;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  width: 100%;
  margin-bottom: 10px;
`;

const ContactItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  ${mobile({ fontSize: "14px" })}
`;

const Copyright = styled.h5``;

const Payment = styled.img`
  width: 80%;
`;

const SubFooter = styled.div`
  margin: auto;
  background-color: #fafafa;
`;

const SubFooterLeft = styled.div`
  padding: 20px;
`;

const SubFooterRight = styled.div`
  padding: 20px;
`;

const CustomLink = styled.a``;

const ListItemLink = styled(Link)`
  color: #000;
  text-decoration: none;

  &:hover {
    color: #2e2e2e;
  }
`;

const InputContainer = styled.div`
  flex: 5;
  width: 15%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1px solid #e5e5e5;
  margin-top: 1rem;
  ${mobile({
  flex: 1,
  margin: "1rem 0rem 1rem 1rem",
  width: "90%",
})}
`;

const Input = styled.input`
  border: none;
  flex: 8;
  background-color: #f5f5f5;
  margin: 0px 10px;
  padding: 0px 10px;
  height: 100%;

  &:focus {
    outline: none;
  }

  ${mobile({
  flex: 5,
  margin: "0px",
  padding: "8px 10px",
})}
`;

const Button = styled.button`
  flex: 1;
  border: none;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  height: 100%;
  ${mobile({
  height: "150%",
})}
`;

const EmptyContainer = styled.div`
  flex: 1;
  ${mobile({ display: "none" })}
`;

const Text = styled.div`
  font-weight: 600;
`;

const Span = styled.span``;

const TextContainer = styled.div`
  ${mobile({
  display: "none",
})}
`;

const WhatsappImage = styled.img`
  width: 25px;
  height: 25px;

  ${mobile({
  marginTop: "-5px",
})}
`;

const currentYear = new Date().getFullYear();

const Footer = () => {
  const dispatch = useDispatch();
  const sidebarState = useSelector((state) => state.sidebar.open);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isSubscribing, setIsSubscribing] = useState(false);
  const store = useSelector((state) => state.store.stores[0]);
  const [t, i18n] = useTranslation("common");
  const language = useSelector((state) => state.store.language);

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);

    await subscribe(email, false)
      .then(function () {
        setLoading(false);
        setIsSubscribing(true);
      })
      .catch(function () {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStore(dispatch);
  }, [dispatch]);

  return (
    <Container sidebarState={sidebarState}>
      <Wrapper>
        <SocialContainer>
          <CustomLink
            href="https://www.facebook.com/laboratoireluxecosmetique"
            target="_blank"
          >
            <SocialIcon color="000">
              <BsFacebook />
            </SocialIcon>
          </CustomLink>
          <CustomLink
            href="https://www.youtube.com/channel/UC1M20JJTi6Tz5v8PInmiBHw/videos"
            target="_blank"
          >
            <SocialIcon color="000">
              <BsYoutube />
            </SocialIcon>
          </CustomLink>
          <CustomLink href="#" target="_blank">
            <SocialIcon color="000">
              <BsInstagram />
            </SocialIcon>
          </CustomLink>
        </SocialContainer>
        <InputContainer>
          {!isSubscribing && (
            <Input
              placeholder={t("footer.newsletter.placeholder")}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
          {!isSubscribing && (
            <Button onClick={handleClick}>
              {loading ? (
                <Ring size="16" color="#fff" strokeColor="#000" />
              ) : (
                <AiOutlineSend style={{ fontSize: 24 }} />
              )}
            </Button>
          )}
          {isSubscribing && <Text>{t("footer.newsletter.confirm")}</Text>}
        </InputContainer>
        <EmptyContainer></EmptyContainer>
      </Wrapper>
      <Wrapper>
        <Left>
          <Logo>LABORATOIRE LUXE COSMÉTIQUE</Logo>
          <Description>
            {t("footer.site_description")}
          </Description>
        </Left>
        <CenterLeft>
          <Title>
            {t("footer.footer_links_1_title")}
          </Title>
          <List>
            {t("footer.footer_links_1", { returnObjects: true }).map((item, index) => (
              <ListItem key={index}>
                <ListItemLink to={item.url}>{item.title}</ListItemLink>
              </ListItem>
            ))}
          </List>
        </CenterLeft>
        <CenterRight>
          <Title>
            {t("footer.footer_links_2_title")}
          </Title>
          <List>
            <List>
              {t("footer.footer_links_2", { returnObjects: true }).map((item, index) => (
                <ListItem key={index}>
                  <ListItemLink to={item.url}>{item.title}</ListItemLink>
                </ListItem>
              ))}
            </List>
          </List>
        </CenterRight>
        <Right>
          <Title>{t("footer.footer_contact.title")}</Title>
          <ContactItem>
            <RiMapPin2Fill style={{ marginRight: "10px" }} />
            {language === 'fr' ? 'Bénin' : 'Benin'}, Cotonou
          </ContactItem>
          <ContactItem style={{ alignItems: "start" }}>
            <RiPhoneFill style={{ marginRight: "10px", marginTop: "3px" }} />
            {store?.phones?.map((phone, index) => (
              <>
                {index === 0 && <WhatsappImage src={WhatsappImg} alt="Whatsapp" />}
                {phone}
                {index !== store?.phones?.length - 1 && <br />}
              </>
            ))}
          </ContactItem>
          <ContactItem>
            <RiMailFill style={{ marginRight: "10px", marginTop: "3px" }} />
            {store?.email}
          </ContactItem>
          <TextContainer>
            {t("footer.footer_contact.hours")}
          </TextContainer>
        </Right>
      </Wrapper>

      <SubFooter>
        <Wrapper>
          <SubFooterLeft>
            <Copyright>@ {currentYear}. {t("footer.sub_footer.allRights")}</Copyright>
          </SubFooterLeft>
          <SubFooterRight>
            <Payment src={paymentMethods} />
          </SubFooterRight>
        </Wrapper>
      </SubFooter>
    </Container>
  );
};

export default Footer;
