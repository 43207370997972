import { createSlice } from "@reduxjs/toolkit";

export const storeSlice = createSlice({
  name: "store",
  initialState: {
    stores: [],
    language: "fr",
    isFetching: false,
    error: false,
  },
  reducers: {
    getStoreStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getStoreSuccess: (state, action) => {
      state.isFetching = false;
      state.stores = action.payload;
    },
    getStoreFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    updateLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const {
  getStoreStart,
  getStoreSuccess,
  getStoreFailure,
  updateLanguage,
} = storeSlice.actions;

export default storeSlice.reducer;
