import styled from "styled-components";
import { mobile, touch } from "../responsive";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 80rem;
  margin: auto;
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
`;

const Title = styled.h1`
  font-weight: bold;
  padding: 2rem;
  ${mobile({
  fontSize: "20px",
  margin: 0,
  padding: "1rem",
})}
`;

const Paragraph = styled.p`
  line-height: 1.5;
  text-align: left;
  color: #000;
  margin-bottom: 1rem;
  padding: 2rem 4rem;
  ${mobile({
  padding: "0 1rem",
})}
  ${touch({
  padding: "0 2rem",
})}
`;

const Br = styled.br``;

const Copyright = () => {
  const sidebarState = useSelector((state) => state.sidebar.open);
  const [t, i18n] = useTranslation("common");

  return (
    <Container sidebarState={sidebarState}>
      <Helmet>
        <title>
          {t("copyright.title")} - Laboratoire Luxe Cosmétique
        </title>
      </Helmet>
      <Title>
        {t("copyright.text")}
      </Title>
      <Paragraph>
        {t("copyright.paragraphs", { returnObjects: true }).map((paragraph, index) => (
          <>
            {paragraph.content}
            {index !== t("copyright.paragraphs").length - 1 && (
              <>
                <Br></Br>
                <Br></Br>
              </>
            )}
          </>
        ))}
      </Paragraph>

    </Container>
  );
};

export default Copyright;
