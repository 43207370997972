import styled from "styled-components";
import { mobile } from "../responsive";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/apiRequests";
import { useEffect, useState } from "react";
import { resetError } from "../redux/userRedux";
import { Helmet } from "react-helmet";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { publicRequest } from "../requestMethods";
import loaderSvg from "../assets/loader.svg";

const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
`;

const TextWrapper = styled.div`
  width: 25%;
  padding: 20px;
  text-align: center;
  ${mobile({ width: "75%" })}
`;

const Wrapper = styled.div`
  width: 25%;
  padding: 20px;
  border: 1px solid #ccc;
  ${mobile({ width: "75%" })}
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  padding: 10px;
  margin: 10px 0px;
  border: 1px solid #ccc;
`;

const Button = styled.button`
  width: 20%;
  padding: 10px;
  background-color: #000;
  margin: auto;
  border: none;
  cursor: pointer;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const CustomLink = styled(Link)`
  margin: 10px 0px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: #000 !important;
`;

const Error = styled.span`
  color: red;
  text-align: center;
  font-size: 12px;
  margin: 10px 0px;
`;

const Success = styled.span`
  color: green;
  text-align: center;
  font-size: 12px;
  margin: 10px 0px;
`;

const Text = styled.span`
  font-size: 12px;
  text-align: center;
`;

const Image = styled.img`
  width: 115%;
  height: 20px;

  ${mobile({
    width: "130%",
  })}
`;

const ForgotPassword = () => {
  const sidebarState = useSelector((state) => state.sidebar.open);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [t, i18n] = useTranslation("common");
  const token = location.pathname.split("/")[2];

  useEffect(() => {}, [email]);

  const handleClick = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    const emailReg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (emailReg.test(email) && location.pathname === "/forgot-password") {
      setLoading(true);
      const forgot = await publicRequest.post("/auth/forgot-password", {
        email,
      });

      if (forgot.data.message === "Email sent") {
        setMessage("success");
      } else {
        setError(forgot.data.message);
      }
      setLoading(false);
    } else if (
      password &&
      confirmPassword &&
      location.pathname !== "/reset-password"
    ) {
      if (password === confirmPassword) {
        setLoading(true);
        const reset = await publicRequest.post("/auth/reset-password", {
          token,
          password,
        });

        if (reset.status === 200) {
          history.push("/login");
          setLoading(false);
        } else {
          setError(reset.data.message);
          setLoading(false);
        }
      } else {
        setError("Not match");
      }
    }
  };

  return (
    <Container sidebarState={sidebarState}>
      <Helmet>
        <title>{t("forgotPassword.title")} - Laboratoire Luxe Cosmétique</title>
      </Helmet>
      <TextWrapper>
        {location.pathname === "/forgot-password" && (
          <Text>{t("forgotPassword.subtitle")}</Text>
        )}
      </TextWrapper>
      <Wrapper>
        <Title>{t("forgotPassword.title")}</Title>
        <Form>
          <Error>
          {error && error !== 'Not match' && <Text>{t("forgotPassword.form.error")}</Text>}
          {error && error === 'Not match' && <Text>{t("forgotPassword.form.errorNotMatch")}</Text>}
          </Error>
          <Success>
            {message && <Text>{t("forgotPassword.form.success")}</Text>}
          </Success>
          {location.pathname === "/forgot-password" && (
            <Input
              placeholder={t("forgotPassword.form.email")}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
          {location.pathname !== "/forgot-password" && (
            <Input
              type="password"
              placeholder={t("forgotPassword.form.password")}
              onChange={(e) => setPassword(e.target.value)}
            />
          )}
          {location.pathname !== "/forgot-password" && (
            <Input
              type="password"
              placeholder={t("forgotPassword.form.confirmPassword")}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          )}
          <Button onClick={handleClick} disabled={loading}>
            {loading ? (
              <Image src={loaderSvg} alt="loader" />
            ) : location.pathname !== "/forgot-password" ? (
              t("forgotPassword.form.validate")
            ) : (
              t("forgotPassword.form.submit")
            )}
          </Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default ForgotPassword;
