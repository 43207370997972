import { useState, useEffect } from "react";
import styled from "styled-components";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { sliderItems } from "../assets/data";
import { mobile } from "../responsive";
import Slider from 'react-animated-slider';
import "react-animated-slider/build/horizontal.css";
import "../css/slider-animation.css";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  position: relative;
  overflow: hidden;
  ${mobile({ height: "40vh" })}
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  transition: all 0.3s ease-in-out;
  background-color: ${(props) => props.currentSlide === 0 ? "#fff7f7" : props.currentSlide === sliderItems.length - 1 ? "#ECF0F1" : "#F7F7F7"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
  &:hover {
    opacity: 1;
  }

  ${mobile({ height: "30px", width: "30px" })}
`;

const Slide = styled.div`
  display: flex;
  align-items: center;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  //position: ${(props) => props.slideIndex === 0 ? "relative" : "absolute"};
  //${mobile({ height: "40vh" })}
`;

const ImgContainer = styled.div`
  height: 100%;
  flex: 1;
  margin: 0px 50px;
  text-align: center;
`;

const Image = styled.img`
  height: 80%;
  margin-top: 5rem;
  transition: ${(props) => (props.isSliding ? "all 0.5s ease" : "all 2s ease")};
  ${mobile({ height: "100%", position: "absolute", marginTop: ".2rem", left: "-7rem", right: "0" })}
`;

const InfoContainer = styled.div`
  padding: 50px;
  flex: 1;
  ${mobile({
  position: "absolute",
  marginTop: (props) => (props.iOS ? "-10rem" : "-15rem"),
  flexWrap: "wrap",
  display: "none"
})}
`;

const Title = styled.h1`
  font-size: 70px;
  color: #fff;
  transition: ${(props) =>
    props.isSliding ? "all 0.3s ease" : "all 0.5s ease"};
  ${mobile({ fontSize: "18px" })}
`;

const Description = styled.p`
  padding: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-right: 1rem;
  color: #fff;
  transition: ${(props) =>
    props.isSliding ? "all 0.3s ease" : "all 0.4s ease"};
  ${mobile({ fontSize: "12px", marginRight: "1rem" })}
`;

const Button = styled.button`
  padding: 10px;
  font-size: 20px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  transition: ${(props) =>
    props.isSliding ? "all 0.3s ease" : "all 0.8s ease"};
  ${mobile({ fontSize: "12px" })}
`;

const Slideshow = () => {
  const history = useHistory();
  const [slideIndex, setSlideIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const language = useSelector((state) => state.store.language);

  // autoplay slider

  const handleClick = (direction) => {
    setIsSliding(true);
    setTimeout(() => {
      setIsSliding(false);
    }, 1000);
  };

  const iOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  return (
    <Container>
      <Slider className="slideshow" autoplay={3000}
        onSlideChange={(event) => {
          setSlideIndex(event.slideIndex);
          handleClick();
        }}
        previousButton={<Arrow direction="left" currentSlide={slideIndex} onClick={() => handleClick("left")}>
          <IoIosArrowBack />
        </Arrow>}
        nextButton={<Arrow direction="right" currentSlide={slideIndex} onClick={() => handleClick("right")}>
          <IoIosArrowForward />
        </Arrow>}
      >
        {language === 'fr' ? sliderItems.fr.map((item, index) => (
          <Slide slideIndex={slideIndex} bg={item.bg} key={index}>
            <ImgContainer>
              <Image isSliding={isSliding} src={item.image} />
            </ImgContainer>
            <InfoContainer iOS={iOS()}>
              <Title isSliding={isSliding}>{item.title}</Title>
              <Description isSliding={isSliding}>
                {item.description}
              </Description>
              {item.button && (
                <Button
                  onClick={() => {
                    history.push(item.link);
                  }}
                  isSliding={isSliding}>{item.button}</Button>)}
            </InfoContainer>
          </Slide>
        )) : sliderItems.en.map((item, index) => (
          <Slide slideIndex={slideIndex} bg={item.bg} key={index}>
            <ImgContainer>
              <Image isSliding={isSliding} src={item.image} />
            </ImgContainer>
            <InfoContainer iOS={iOS()}>
              <Title isSliding={isSliding}>{item.title}</Title>
              <Description isSliding={isSliding}>
                {item.description}
              </Description>
              {item.button && (
                <Button
                  onClick={() => {
                    history.push(item.link);
                  }}
                  isSliding={isSliding}>{item.button}</Button>)}
            </InfoContainer>
          </Slide>
        ))
        }
      </Slider>
    </Container>
  );
};

export default Slideshow;
