import { useEffect } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { mobile } from "../responsive";
import queryString from "query-string";
import { useState } from "react";
import { subscribe } from "../redux/apiRequests";
import { useTranslation } from "react-i18next";
import logo from "../assets/LLC.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  background-color: #fff;
  max-width: 80rem;
  margin: auto;
  margin-top: 20px;
  padding: 2rem 12rem;
  ${mobile({ padding: "2rem 1rem", margin: "2rem 1rem" })}
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.25);
  text-align: center;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  ${mobile({ fontSize: "16px" })}
`;

const Text = styled.h1`
  font-size: 16px;
  margin-top: 20px;
  font-weight: 500;
`;

const Paragraph = styled.div`
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  ${mobile({ fontSize: "14px" })}
`;

const Logo = styled.img`
  width: 70px;
  height: 70px;
  margin-left: -1rem;
  ${mobile({ width: "50px", height: "50px", marginLeft: "-0.5rem" })}
`;


const ConfirmSubscription = () => {
  const location = useLocation();
  const [email_confirmed, setEmail_confirmed] = useState(false);
  const [error, setError] = useState(false);
  const [t, i18n] = useTranslation("common");

  useEffect(() => {
    let search = location.search;
    let params = queryString.parse(search);
    let email = params.email;

    const confirm = async () => {
      await subscribe(email, true)
        .then(function () {
          setEmail_confirmed(true);
        })
        .catch(function () {
          setError(true);
        });
    };
    confirm();
  }, [location]);

  return (
    <Container>
      {email_confirmed && (
        <Wrapper>
          <Logo src={logo}
            alt="logo"
            alt={`Luxe Cosmetique`}
            title={`Luxe Cosmetique`}
          />
          <Title>{t("newsletter.title")}</Title>
          <Paragraph>{t("newsletter.description")}</Paragraph>
        </Wrapper>
      )}
      {error && <Text>{t("newsletter.expired")}</Text>}
    </Container>
  );
};

export default ConfirmSubscription;
