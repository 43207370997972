import { useEffect, useState } from "react";
import styled from "styled-components";
import { BiCart, BiSearch } from "react-icons/bi";
import { AiOutlineUser, AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Badge } from "@mui/material";
import { mobile, touch } from "../responsive";
import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { opened, closed } from "../redux/sidebarRedux";
import { getCart, getCategories, getRanges, getUser, updateCart } from "../redux/apiRequests";
import { MdKeyboardArrowDown } from "react-icons/md";
import { logout } from "../redux/userRedux";
import logo from "../assets/LLC.png";
import { styled as muiStyled } from '@mui/material/styles';
import { resetCart } from "../redux/cartRedux";
import { useRef } from "react";
import { publicRequest } from "../requestMethods";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  height: 120px;
  background-color: white;
  ${mobile({ height: "130px" })}
`;

const Wrapper = styled.div`
  position: relative;
  height: 70px;
  max-width: 80rem;
  margin: auto;
  padding: 10px 20px;
  display: flex;
  z-index: 99;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(86%)" : "translateX(0%)")};
  ${mobile({ padding: "10px 0px" })}
`;

const StyledBadge = muiStyled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    backgroundColor: "#ffc107",
    padding: '2px 5px',
    height: 'auto',
    lineHeight: "17px"
  },
}));


const Megamenu = styled.ul`
  max-width: 85rem;
  margin: auto;
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  @media (max-width: 768px) {
    max-width: 17rem;
    flex-flow: column nowrap;
    background-color: white;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0%)" : "translateX(-100%)")};
    top: 0;
    left: 0;
    height: 100vh;
    width: 300px;
    padding-top: 15px;
    transition: transform 0.3s ease-in-out;
    z-index: 50;
    overflow: hidden;
    overflow-y: scroll;

  }
`;

const MegamenuItem = styled.li`
  display: flex;
  cursor: pointer;
  font-size: 16px;
  justify-content: center;
  margin: 0 20px;
  align-items: center;
  align-self: center;
  overflow: hidden;

  @media (max-width: 768px) {
    margin-bottom: 20px;
    align-self: initial;
    overflow: initial;
    display: initial;
  }
`;

const TouchMegamenuItem = styled(MegamenuItem)`
  ${mobile({ display: "none" })}
`

const MobileMegamenuItem = styled.li`
  cursor: pointer;
  font-size: 16px;
  justify-content: center;
  margin: 0 20px;
  align-items: center;
  display: none;

  @media (max-width: 768px) {
    margin-bottom: 20px;
    display: block;
  }
`;

const Top = styled.div`
  text-align: ${({ logged }) => (logged ? "end" : "initial")};
  justify-content: ${({ logged }) => (logged ? "initial" : "space-between")};
  padding-right: ${({ logged }) => (logged ? "10px" : "initial")};
  margin-bottom: ${({ logged }) => (logged ? "initial" : "1rem")};
  display: none;

  @media only screen and (max-width: 415px) {
    display: ${({ logged }) => (logged ? "block" : "flex")};
  }
`;

const CustomMegamenuItem = styled.li`
  cursor: pointer;
  font-size: 16px;
  justify-content: center;
  margin: 0 20px;
  align-items: center;
  display: none;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }

  ${mobile({ display: "block" })}
`;

const Bottom = styled.div`
  display: none;
  margin: auto;
  margin-bottom: 2rem;
  ${mobile({ display: "flex" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const BrowseMenuItem = styled.div`
  display: none;
  padding: 0px 10px;
  ${mobile({ display: "block" })}
`;

const Center = styled.div`
  flex: 1;
  ${mobile({ display: "none" })}
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
`;

const SearchContainer = styled.div`
  border: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  margin: 20px 0px;
  padding: 5px;
`;

const MobileSearchContainer = styled.div`
  border: 0.5px solid lightgray;
  align-items: center;
  margin: 15px;
  padding: 5px;
  display: none;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(94%)" : "translateX(0%)")};
  ${mobile({
  padding: "10px 2px", display: "flex", marginTop: "-1.2rem",
})}
`;

const Input = styled.input`
  flex: 1;
  border: none;
  &:focus {
    outline: none;
  }
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-left: -1rem;
  ${mobile({ width: "50px", height: "50px", marginLeft: "-0.5rem" })}
`;

const MenuItem = styled.div`
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const CustomLink = styled.a``;

const Separator = styled.br`
  display: none;
  ${mobile({ display: "block" })}
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${mobile({ display: "none" })}
`;

const Username = styled.div`
  font-size: 16px;
  cursor: pointer;
`;

const UserLoggedIn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const DropDownMenu = styled.div`
  position: absolute;
  width: 140px;
  margin-top: 5px;
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  z-index: 80;
  border-radius: 0;
  -webkit-transform: translateZ(0) !important;
  transform: translateZ(0) !important;
  border: 0;
  visibility: ${({ dropdownOpen }) => (dropdownOpen ? "visible" : "hidden")};
  opacity: ${({ dropdownOpen }) => (dropdownOpen ? "1" : "0")};
  transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: ease-in 0.3s;
  -ms-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  -webkit-box-shadow: 0 0 3.76px 0.24px rgb(0 0 0 / 15%);
  box-shadow: 0 0 3.76px 0.24px rgb(0 0 0 / 15%);

  ${mobile({
  display: "none",
})}
`;

const DropDownMenuItem = styled(Link)`
  font-style: 16px;
  cursor: pointer;
  margin: 2px 5px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #000;

  &:hover {
    color: #333333;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #dedede;
    padding-bottom: 0.5rem;
  }
`;

const MegamenuDropdown = styled.div`
  position: absolute;
  top: 10em;
  left: 9rem;
  display: flex;
  flex-flow: column nowrap;
  z-index: 80;
  border-radius: 0;
  background: #fff;
  border: 0;
  max-height: ${({ megamenDropdownOpen }) => (megamenDropdownOpen ? "500px" : "0")};
  opacity: ${({ megamenDropdownOpen }) => (megamenDropdownOpen ? "1" : "0")};
  -webkit-transition: all .5s ease-in-out 0s;
  transition: all .5s ease-in-out 0s;
  -webkit-box-shadow: 0 0 3.76px 0.24px rgb(0 0 0 / 15%);
  box-shadow: 0 0 3.76px 0.24px rgb(0 0 0 / 15%);
  overflow: hidden;

  @media (min-width: 1025px) {
    left: 3.5rem;
  }

  @media(min-width: 1400px) {
    left: calc((100vw - 1300px) / 2);
  }

  ${touch({
  left: "3.5rem",
})}
`;

const MegamenuDropdownItem = styled.li`
  font-size: 12px;
  cursor: pointer;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #000;
  background: #fff;

  &:hover {
    color: #333333;
    background: #f5f5f5;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #dedede;
    padding-bottom: 0.5rem;
  }

  ${mobile({
  display: "none",
})}
`;

const SearchResults = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  margin-top: -1.1rem;
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  z-index: 9999;
  border-radius: 0;
  -webkit-transform: translateZ(0) !important;
  transform: translateZ(0) !important;
  border: 0;
  visibility: ${({ searchResultsOpen }) => (searchResultsOpen ? "visible" : "hidden")};
  opacity: ${({ searchResultsOpen }) => (searchResultsOpen ? "1" : "0")};
  transition: 0.1s ease-in;
  -webkit-transition: 0.1s ease-in;
  -moz-transition: ease-in 0.1s;
  -ms-transition: ease-in 0.1s;
  -o-transition: ease-in 0.1s;
  -webkit-box-shadow: 0 0 3.76px 0.24px rgb(0 0 0 / 15%);
  box-shadow: 0 0 3.76px 0.24px rgb(0 0 0 / 15%);

  ${mobile({
  margin: "15px",
  padding: "5px",
  marginTop: "-0.8rem",
  position: "relative"
})}
`;

const SearchResult = styled.div`
  display: flex;
  font-size: 12px;
  cursor: pointer;
  padding: 10px 15px;
  align-items: center;
  text-decoration: none;
  color: #000;
  background: #fff;

  &:hover {
    color: #333333;
    background: #f5f5f5;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #dedede;
    padding-bottom: 0.5rem;
  }
`;

const SearchResultImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const SearchResultName = styled.span`
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #000;

  &:hover {
    color: #333333;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #dedede;
    padding-bottom: 0.5rem;
  }

  ${mobile({
  fontSize: "14px",
  marginRight: "5px",
})}
`;

const SeeAll = styled.div`
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #000;

  &:hover {
    color: #333333;
  }
`;

const MegamenuItemWrapper = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  align-self: center;;
`;

const Text = styled.span`
  cursor: pointer;
  margin-left: 5px;
  margin-top: 2px;

  ${mobile({
  display: "none",
})}
`;

const Navbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const open = useSelector((state) => state.sidebar.open);
  const quantity = useSelector((state) => state.cart.quantity);
  const cart = useSelector((state) => state.cart);
  const categories = useSelector((state) => state.category.categories);
  const categoriesToExclude = ["best-sellers"];
  const categoriesToOfMenuExclude = ["what's-new"];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [megamenDropdownOpen, setMegamenDropdownOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const ref = useRef(null);
  const userRef = useRef(null);
  const searchRef = useRef(null);
  const [results, setResults] = useState([]);
  const [resultsList, setResultsList] = useState([]);
  const [searchResultsOpen, setSearchResultsOpen] = useState(false);
  const [input, setInput] = useState("");
  const [t, i18n] = useTranslation("common");
  const language = useSelector((state) => state.store.language);

  useEffect(() => {
    getRanges(dispatch);
    user.currentUser && getUser(user.currentUser._id, dispatch);
    user.currentUser && getCart(user.currentUser._id, dispatch);
  }, [dispatch]);

  const handleClick = () => {
    dispatch(opened());
    if (!open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, { passive: true });
    document.addEventListener('click', handleUserClickOutside, { passive: true });
    document.addEventListener('click', handleSearchClickOutside, { passive: true });
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      document.removeEventListener('click', handleClickOutside, { passive: true });
      document.removeEventListener('click', handleUserClickOutside, { passive: true });
      document.removeEventListener('click', handleSearchClickOutside, { passive: true });
      window.removeEventListener('resize', handleWindowSizeChange, { passive: true });
    };
  });

  const iOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setInput("");
    setResults([]);
    setResultsList([]);
  };

  const handleMegamenu = (e) => {
    setMegamenDropdownOpen(!megamenDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setMegamenDropdownOpen(false);
    }
  };

  const handleUserClickOutside = (event) => {
    if (userRef.current && !userRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const handleSearchClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchResultsOpen(false);
      setInput("");
    }
  };

  const handleClose = () => {
    dispatch(closed());
    document.body.style.overflow = "initial";
  };

  useEffect(() => {
    if (open) {
      dispatch(closed());
      document.body.style.overflow = "initial";
    }
  }, []);

  useEffect(() => {
    getCategories(dispatch);
  }, [dispatch]);

  const loggedOut = (e) => {
    dispatch(logout());
    dispatch(resetCart())
    handleClose();
    handleDropdown(false);
  };

  const handleDropdown = (state) => {
    setDropdownOpen(state);
  };

  const search = async (e) => {
    e.preventDefault();
    const query = e.target.value;
    setInput(query);

    if (query) {
      try {
        const res = await publicRequest.get(`/products/search?q=${query}`);
        setResults(res.data);
        setSearchResultsOpen(true);
      } catch (err) {
      }
    }
  };

  const searchMobile = async (e) => {
    e.preventDefault();
    const query = e.target.value;
    setInput(query);

    if (query) {
      try {
        const res = await publicRequest.get(`/products/search?q=${query}`);
        setResultsList(res.data);
        setSearchResultsOpen(true);
      } catch (err) {
      }
    }
  };

  const encoreURI = (asset_url) => {
    // encode ( by %)
    return encodeURI(asset_url);
  };

  return (
    <Container>
      <Wrapper open={open}>
        <Left>
          <BrowseMenuItem onClick={handleClick}>
            <AiOutlineMenu />
          </BrowseMenuItem>
          <Link style={{ textDecoration: "none", color: "initial" }} to="/">
            <Logo src={logo}
              alt="logo"
              alt={`Luxe Cosmetique`}
              title={`Luxe Cosmetique`}
            />
          </Link>
        </Left>
        <Center ref={searchRef}>
          <SearchContainer>
            <Input name="search" value={input}
              autoComplete="off"
              placeholder={t("header.search.placeholder")}
              onChange={search} />
            <BiSearch style={{ color: "gray", fontSize: 20, cursor: "pointer" }} />
          </SearchContainer>
          {results.length > 0 && input && (
            <SearchResults searchResultsOpen={searchResultsOpen}>
              {results?.slice(0, 3)
                .map((product) => (
                  <Link style={{ textDecoration: "none", color: "initial" }}
                    to={`/product/${product.slug}`}>
                    <SearchResult
                      onClick={() => {
                        setSearchResultsOpen(false);
                        setInput("");
                      }}
                    >
                      <SearchResultImage src={`${process.env.REACT_APP_ASSETS_URL}/products/${encoreURI(
                        product.images[0]
                      )}`}
                        alt={`${language === 'fr' ? product.name : product.en.name}`}
                        title={`${language === 'fr' ? product.name : product.en.name}`}
                      />
                      <SearchResultName>{language === 'fr' ? product.name : product.en.name}</SearchResultName>
                    </SearchResult>
                  </Link>
                ))}
              {results.length > 3 && (
                <Link to={`/products/search?q=${input}`}
                  style={{ textDecoration: "none", color: "initial" }}>
                  <SeeAll
                    onClick={() => {
                      setSearchResultsOpen(false);
                      setInput("");
                    }}
                  >
                    {t("header.search.see_all")}
                  </SeeAll>
                </Link>)}
            </SearchResults>)}
        </Center>
        <Right>
          <MenuItem>
            {((user && user.currentUser && user.currentUser.role !== "user") ||
              (user && !user.currentUser)) && (
                <Link style={{ display: "flex", textDecoration: "none", color: "initial" }}
                  style={{ textDecoration: "none", color: "initial" }}
                  to="/login"
                >
                  <AiOutlineUser style={{ fontSize: 24 }} />
                </Link>
              )}
            {user && user.currentUser && user.currentUser.role === "user" && (
              <UserContainer onClick={handleDropdown.bind(this, !dropdownOpen)}>
                <Username>{user.currentUser.username}</Username>
                <MdKeyboardArrowDown />
              </UserContainer>
            )}
            {user && user.currentUser && user.currentUser.role === "user" && (
              <DropDownMenu dropdownOpen={dropdownOpen}>
                <DropDownMenuItem
                  to="/myaccount"
                  onClick={() => {
                    handleDropdown.bind(this, !dropdownOpen);
                    setDropdownOpen(false);
                  }}>
                  {t("header.account.my_account")}
                </DropDownMenuItem>
                <DropDownMenuItem to="/" onClick={loggedOut}>
                  {t("header.account.log_out")}
                </DropDownMenuItem>
              </DropDownMenu>
            )}
          </MenuItem>
          <Link style={{ display: "flex", textDecoration: "none", color: "initial" }}
            to="/cart">
            <MenuItem>
              <StyledBadge color="primary" badgeContent={quantity} style={{ color: "red !important" }}>
                <BiCart style={{ fontSize: 24 }} />
              </StyledBadge>
            </MenuItem>
          </Link>
        </Right>
      </Wrapper>
      <MobileSearchContainer open={open} iOS={iOS}>
        <Input name="search" value={input}
          autoComplete="off"
          placeholder={t("header.search.placeholder")}
          onChange={searchMobile} />
        <BiSearch style={{ color: "gray", fontSize: 24 }} />
      </MobileSearchContainer>
      {resultsList.length > 0 && input && (
        <SearchResults
          searchResultsOpen={searchResultsOpen}>
          {resultsList?.slice(0, 3)
            .map((product) => (
              <Link style={{ textDecoration: "none", color: "initial" }}
                to={`/product/${product.slug}`}>
                <SearchResult
                  onClick={() => {
                    setSearchResultsOpen(false);
                    setInput("");
                  }}
                >
                  <SearchResultImage src={`${process.env.REACT_APP_ASSETS_URL}/products/${encoreURI(
                    product.images[0]
                  )}`}
                    alt={`${language === 'fr' ? product.name : product.en.name}`}
                    title={`${language === 'fr' ? product.name : product.en.name}`}
                  />
                  <SearchResultName>{language === 'fr' ? product.name : product.en.name}</SearchResultName>
                </SearchResult>
              </Link>
            ))}
          {results.length > 3 && (
            <SeeAll>
              {t("header.search.see_all")}
            </SeeAll>)}
        </SearchResults>)}
      <Megamenu open={open}>
        <Top ref={userRef}
          logged={
            user && user.currentUser && user.currentUser.role === "user"
              ? false
              : true
          }
        >
          {user && user.currentUser && user.currentUser.role === "user" && (
            <UserLoggedIn>
              {t("header.welcome")}
              {user.currentUser.username.length <= 15 && (
                <Username>&nbsp; {user.currentUser.username}</Username>
              )}
            </UserLoggedIn>
          )}
          <AiOutlineClose onClick={handleClick} style={{ fontSize: 24 }} />
        </Top>
        {user && user.currentUser && user.currentUser.role === "user" && (
          <CustomMegamenuItem>
            <Link
              style={{ textDecoration: "none", color: "initial" }}
              to="/myaccount"
              onClick={handleClose}
            >
              {t("header.account.my_account")}
            </Link>
          </CustomMegamenuItem>
        )}
        {user && user.currentUser && user.currentUser.role === "user" && (
          <CustomMegamenuItem>
            <Link
              style={{ textDecoration: "none", color: "initial" }}
              to="/"
              onClick={loggedOut}
            >
              {t("header.account.log_out")}

            </Link>
          </CustomMegamenuItem>
        )}

        {user && user.currentUser && user.currentUser.role === "user" && (
          <Separator></Separator>
        )}

        <TouchMegamenuItem ref={ref}>
          <MegamenuItemWrapper onClick={handleMegamenu}>
            <AiOutlineMenu
            /> <span className="Megamenu"
              style={{ paddingLeft: "0.5rem", paddingTop: "1px" }}>
              {t("header.navigation.all")}
            </span>
          </MegamenuItemWrapper>
          <MegamenuDropdown itemscope itemtype="https://schema.org/SiteNavigationElement"
            megamenDropdownOpen={megamenDropdownOpen}>
            {categories
              .filter((category) => !categoriesToOfMenuExclude.includes(category.slug))
              .map((category) =>
                <Link
                  style={{ textDecoration: "none", color: "initial" }}
                  to={`/products/${category.slug}`}
                  onClick={() => {
                    setMegamenDropdownOpen(false)
                  }}>
                  <MegamenuDropdownItem>{language === 'fr' ? category.name : category.en.name}</MegamenuDropdownItem>
                </Link>
              )}
          </MegamenuDropdown>
        </TouchMegamenuItem>
        <MegamenuItem>
          <Link
            itemprop="url"
            style={{ textDecoration: "none", color: "initial" }}
            to="/products/"
            onClick={() => {
              setMegamenDropdownOpen(false)
            }}>
            {t("header.navigation.all_products")}
          </Link>
        </MegamenuItem>
        <MegamenuItem>
          <Link
            itemprop="url"
            style={{ textDecoration: "none", color: "initial" }}
            to="/ranges"
            onClick={handleClose}
          >
            {t("header.navigation.ranges")}
          </Link>
        </MegamenuItem>
        {width >= 415 ? categories
          .filter((category) => !categoriesToExclude.includes(category.slug))
          .map((category) => (
            <>
              {category.products.length > 0 &&
                (category.slug === "what's-new") &&
                <MegamenuItem key={category.id}>
                  <Link
                    style={{ textDecoration: "none", color: "initial" }}
                    to={`/products/${category.slug}`}
                    onClick={handleClose}
                  >
                    {language === 'fr' ? category.name : category.en.name}
                  </Link>
                </MegamenuItem>}
            </>
          )) : categories
            .map((category) => (
              <>
                {category.products.length > 0 &&
                  <MegamenuItem key={category.id}>
                    <Link
                      style={{ textDecoration: "none", color: "initial" }}
                      to={`/products/${category.slug}`}
                      onClick={handleClose}
                    >
                      {language === 'fr' ? category.name : category.en.name}
                    </Link>
                  </MegamenuItem>}
              </>
            ))
        }

        <Separator></Separator>

        <MobileMegamenuItem>
          <Link
            style={{ textDecoration: "none", color: "initial" }}
            to={t("header.sidemenu.about.url")}
            onClick={handleClose}
          >
            {t("header.sidemenu.about.title")}
          </Link>
        </MobileMegamenuItem>
        <MobileMegamenuItem onClick={handleClose}>
          {t("header.sidemenu.useful_links.title")}
        </MobileMegamenuItem>
        <MobileMegamenuItem>
          <Link
            style={{ textDecoration: "none", color: "initial" }}
            to={t("header.sidemenu.contact.url")}
            onClick={handleClose}
          >
            {t("header.sidemenu.contact.title")}
          </Link>
        </MobileMegamenuItem>

        <Bottom>
          <CustomLink href="https://www.facebook.com/laboratoireluxecosmetique">
            <MenuItem>
              <SocialIcon color="000">
                <BsFacebook style={{ fontSize: 24 }} />
              </SocialIcon>
            </MenuItem>
          </CustomLink>
          <CustomLink
            href="https://www.youtube.com/channel/UC1M20JJTi6Tz5v8PInmiBHw/videos" StyledBadge
            target="_blank"
          >
            <MenuItem>
              <SocialIcon color="000">
                <BsYoutube style={{ fontSize: 24 }} />
              </SocialIcon>
            </MenuItem>
          </CustomLink>
          <CustomLink href="#" target="_blank">
            <MenuItem>
              <SocialIcon color="000">
                <BsInstagram style={{ fontSize: 24 }} />
              </SocialIcon>
            </MenuItem>
          </CustomLink>
        </Bottom>
      </Megamenu>
    </Container>
  );
};

export default Navbar;