import styled from "styled-components";
import { Link } from "react-router-dom";
import { mobile, touch } from "../responsive";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 80rem;
  margin: auto;
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
`;

const Title = styled.h1`
  font-weight: bold;
  padding: 2rem;
  ${mobile({
  fontSize: "20px",
  margin: 0,
  padding: "1rem",
})}
`;

const SubTitle = styled.h3`
  font-weight: bold;
  margin-top: 1rem;
  padding: 0 2rem;
  ${mobile({
  fontSize: "18px",
  padding: "0 1rem",
})}
`;

const List = styled.ul`
  margin: 0;
  line-height: 1.5;
  text-align: left;
  color: #000;
  margin-bottom: 1rem;
  padding: 2rem;
  ${mobile({
  padding: "1rem 2rem",
})}
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
`;

const Section = styled.section`
  margin-bottom: 1rem;
`;

const SectionTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: left;
  color: #000;
  padding: 0 2rem;
  ${mobile({
  padding: "0 1rem",
})}
`;

const Paragraph = styled.p`
  line-height: 1.5;
  text-align: left;
  color: #000;
  padding: 2rem;
  ${mobile({
  padding: "0 1rem",
})}
  ${touch({
  padding: "0 2rem",
})}
`;

const Br = styled.br``;

const Legend = styled.h5`
  margin-bottom: 1rem;
  text-align: left;
  color: #000;
  font-weight: 300;
  font-style: italic;
`;

const TermsConditions = () => {
  const sidebarState = useSelector((state) => state.sidebar.open);
  const [t, i18n] = useTranslation("common");

  return (
    <Container sidebarState={sidebarState}>
      <Helmet>
        <title>
          {t("terms.title")} - Laboratoire Luxe Cosmétique
        </title>
      </Helmet>
      <Title>
        {t("terms.title")}
      </Title>
      <SubTitle>
        {t("terms.text")}
      </SubTitle>
      <List>
        {t("terms.paragraph.content.list", { returnObjects: true }).map((item, index) => (
          <ListItem>
            {item.content.map((content, index) => (
              <>
                {content}
              </>
            ))}
            {item.link && <Link style={{ fontWeight: 600, color: "black" }} to="/privacy-policy">{item.link}</Link>}
          </ListItem>
        ))}
      </List>

      {
        t("terms.sections", { returnObjects: true }).map((section, index) => (
          <Section>
            <SectionTitle>
              {section.title}
            </SectionTitle>
            {section.paragraphs && section.paragraphs.map((paragraph, index) => (
              <Paragraph>
                {paragraph.content}
                {paragraph.list && <List>
                  {paragraph.list.map((item) => (
                    <ListItem>
                      {item.content}
                      {item.link && <Link style={{ fontWeight: 600, color: "black" }} to="/privacy-policy">{item.link}</Link>}
                    </ListItem>
                  ))}
                </List>}
                {(paragraph.link !== 'ici' && paragraph.link !== 'here') && <Link style={{ fontWeight: 600, color: "black" }} to="/privacy-policy">{paragraph.link}</Link>}
                {(paragraph.link === 'ici' || paragraph.link === 'here') && <Link style={{ fontWeight: 600, color: "black" }} to="/contact">{paragraph.link}</Link>}

                {paragraph.sections && paragraph.sections.map((section, index) => (
                  <>
                    <Br></Br>
                    <Br></Br>
                    <Note>
                      {section.title}
                    </Note>
                    {section.paragraphs.map((paragraph, index) => (
                      <>
                        {paragraph.content}

                        {paragraph.list && <List>
                          {paragraph.list.map((item) => (
                            <ListItem>
                              {item.content}
                            </ListItem>
                          ))}
                        </List>}
                      </>
                    ))}
                  </>
                ))}

                {index !== paragraph.content.length - 1 && <Br></Br>}
              </Paragraph>
            ))}

          </Section>
        ))
      }

      <Legend>{t("terms.legend")}</Legend>
    </Container>
  );
};

export default TermsConditions;
