import styled, { keyframes } from "styled-components";

const motion = (props) => keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RingSpinner = styled.div`
  animation: ${(p) => motion(p)} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  :nth-child(1) {
    animation-delay: -0.45s;
  }
  :nth-child(2) {
    animation-delay: -0.3s;
  }
  :nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const Ring = ({ color, strokeColor, size }) => (
  <RingSpinner>
    <svg
      class="animate-spin"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      fill={strokeColor}
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke={strokeColor}
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill={color}
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </RingSpinner>
);

Ring.defaultProps = {
  size: 16,
  color: "#fff",
  strokeColor: "#000",
};

export default Ring;
