import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 80rem;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
`;

const Title = styled.h1`
  font-size: 14rem;
  font-weight: 700;
  margin-bottom: 1rem;
  ${mobile({
  fontSize: "6rem",
})}
`;

const Text = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
  ${mobile({
  fontSize: "1rem",
})}
`;

const NotFound = () => {
  const sidebarState = useSelector((state) => state.sidebar.open);
  const [t, i18n] = useTranslation("common");

  return (
    <Container sidebarState={sidebarState}>
      <Title>
        {t("404.title")}
      </Title>
      <Text>
        {t("404.pageNotFound")}
      </Text>
    </Container>
  );
};

export default NotFound;