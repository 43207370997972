import styled from "styled-components";
import { Link } from "react-router-dom";
import { mobile, touch } from "../responsive";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 80rem;
  margin: auto;
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
`;

const Title = styled.h1`
  font-weight: bold;
  padding: 2rem;
  ${mobile({
  fontSize: "20px",
  margin: 0,
  padding: "1rem",
})}
`;

const SubTitle = styled.h3`
  font-weight: bold;
  margin-top: 1rem;
  padding: 0 2rem;
  ${mobile({
  fontSize: "18px",
  padding: "0 1rem",
})}
`;

const List = styled.ul`
  margin: 0;
  line-height: 1.5;
  text-align: left;
  color: #000;
  margin-bottom: 1rem;
  padding: 2rem;
  ${mobile({
  padding: "1rem 2rem",
})}
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
`;

const Section = styled.section`
  margin-bottom: 1rem;
`;

const SectionTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: left;
  color: #000;
  padding: 0 2rem;
  ${mobile({
  padding: "0 1rem",
})}
`;

const Paragraph = styled.p`
  line-height: 1.5;
  text-align: left;
  color: #000;
  margin-bottom: 1rem;
  padding: 0rem 4rem;
  ${mobile({
  padding: "0 1rem",
})}
  ${touch({
  padding: "0 2rem",
})}
`;

const Br = styled.br``;

const Note = styled.p`
  font-weight: 600;
`;

const PrivacyPolicy = () => {
  const sidebarState = useSelector((state) => state.sidebar.open);
  const [t, i18n] = useTranslation("common");

  return (
    <Container sidebarState={sidebarState}>
      <Helmet>
        <title>
          {t("privacy.title")} - Laboratoire Luxe Cosmétique
        </title>
      </Helmet>
      <Title>
        {t("privacy.title")}
      </Title>
      <SubTitle>
        {t("privacy.text")}
      </SubTitle>
      <Paragraph>
        {t("privacy.paragraph.content.description", { returnObjects: true })}
        <List>
          {t("privacy.paragraph.content.list", { returnObjects: true }).map((item, index) => (
            <ListItem>
              {item.content.map((content, index) => (
                <>
                  {content}
                  {index !== content.length - 1 && <Br />}
                </>
              ))}
            </ListItem>
          ))}
        </List>
        {t("privacy.paragraph.content.paragraphs", { returnObjects: true }).map((paragraph, index) => (
          <>
            {paragraph.content.map((content) => (content
            ))}
            {index !== paragraph.content.length - 1 && <Br></Br>
            }
          </>
        ))}
        <Br></Br>
        {t("privacy.paragraph.content.link.text")}
        <Link
          style={{ fontWeight: 600, color: "black" }}
          to="/terms-and-conditions"
        >
          {t("privacy.paragraph.content.link.link")}
        </Link>
        .<Br></Br>
        <Br></Br>
        {t("privacy.paragraph.content.note.content", { returnObjects: true }).map((content, index) => (
          content
        ))}
      </Paragraph>

      {
        t("privacy.sections", { returnObjects: true }).map((section, index) => (
          <Section>
            <Title>
              {section.title}
            </Title>
            {section.paragraphs && section.paragraphs.map((paragraph, index) => (
              <Paragraph>
                {paragraph.content}
                {paragraph.list && <List>
                  {paragraph.list.map((item) => (
                    <ListItem>
                      {item.content}
                    </ListItem>
                  ))}
                </List>}

                {paragraph.sections && paragraph.sections.map((section, index) => (
                  <>
                    <Br></Br>
                    <Br></Br>
                    <Note>
                      {section.title}
                    </Note>
                    {section.paragraphs.map((paragraph, index) => (
                      <>
                        {paragraph.content}

                        {paragraph.list && <List>
                          {paragraph.list.map((item) => (
                            <ListItem>
                              {item.content}
                            </ListItem>
                          ))}
                        </List>}
                      </>
                    ))}
                  </>
                ))}

                {paragraph.notes && paragraph.notes.map((note) => (
                  <>
                    <Br></Br>
                    <Br></Br>

                    {note.title && <Note style={{ fontStyle: "italic", fontWeight: 300 }}>
                      {note.title}
                      <Br></Br>
                      <Br></Br>
                    </Note>}
                    {note.subtitle}
                    {note.subtitle && <Br></Br>}
                    {note.list && <List>
                      {note.list.map((item, index) => (
                        <ListItem>
                          {item.content}
                          {index !== note.list.length - 1 && <Br />}
                        </ListItem>
                      ))}
                    </List>}

                    {note.paragraphs && note.paragraphs.map((paragraph, index) => (
                      <>
                        {paragraph.content}
                        <Br></Br>
                        <Br></Br>
                      </>
                    ))}
                  </>
                ))}

                {index !== paragraph.content.length - 1 && <Br></Br>}
              </Paragraph>
            ))}

            {section.paragraph && <Paragraph>
              {section.paragraph.text}
              <Link
                style={{ fontWeight: 600, color: "black" }}
                href="mailto:DDP@laboratoireluxecosmetique.com"
              >
                DDP@laboratoireluxecosmetique.com
              </Link>
              {section.paragraph.email}.
            </Paragraph>}

            {section.paragraph_contact && <Paragraph>
              {section.paragraph_contact}
              <Link
                style={{ fontWeight: 600, color: "black" }}
                href="mailto:care@laboratoireluxecosmetique.com"
              >
                care@laboratoireluxecosmetique.com
              </Link>.
            </Paragraph>}
          </Section>
        ))
      }


    </Container>
  );
};

export default PrivacyPolicy;
