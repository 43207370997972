import styled from "styled-components";
import Products from "../components/Products";
import { mobile } from "../responsive";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getRanges } from "../redux/apiRequests";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 80rem;
  margin: auto;
`;

const Filter = styled.div`
  margin: 20px;
  ${mobile({ width: "0px 20px", display: "flex", flexDirection: "column" })}
`;

const FilterText = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
  ${mobile({ marginRight: "0px" })}
`;

const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  ${mobile({ margin: "10px 0px" })}
`;
const Option = styled.option``;

const ProductList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const sidebarState = useSelector((state) => state.sidebar.open);
  const range = location.pathname.split("/")[3];
  const category = location.pathname.split("/")[2];
  const ranges = useSelector((state) => state.range.ranges);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState("newest");
  const [currentrange, setCurrentRange] = useState("");
  const [t, i18n] = useTranslation("common");
  const language = useSelector((state) => state.store.language);

  const handleFilters = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      [e.target.name]: value,
    });
    setCurrentRange(value);
  };

  useEffect(() => {
    setFilters({});
    setSort("newest");
    setCurrentRange("");
  }, [range, category]);

  return (
    <Container sidebarState={sidebarState}>
      <Helmet>
        <title>{t("product_list.title")} - Laboratoire Luxe Cosmétique</title>
      </Helmet>
      <FilterContainer>
        <Filter>
          <FilterText>{t("product_list.filterby")}</FilterText>
          <Select name="order" onChange={(e) => setSort(e.target.value)}>
            <Option value="newest">{t("product_list.desc")}</Option>
            <Option value="asc">{t("product_list.asc")}</Option>
          </Select>
        </Filter>
        {((category !== "ranges" && !range && !category) || (category === "what's-new" || category === "best-sellers")) && (
          <Filter>
            <FilterText>{t("product_list.sortby")}</FilterText>
            <Select name="range" value={currentrange} onChange={handleFilters}>
              <Option>{t("product_list.range")}</Option>
              {ranges.map((range) => (
                <Option key={range._id} value={range._id}>
                  {language === 'en' ? range.en.name : range.name}
                </Option>
              ))}
            </Select>
          </Filter>
        )}
      </FilterContainer>
      <Products range={range} cat={category} filters={filters} sort={sort} />
    </Container>
  );
};

export default ProductList;
