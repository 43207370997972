import CookieConsent from "react-cookie-consent";
import "../css/Cookie.css";
import { useTranslation } from "react-i18next";

const Cookie = () => {
  const [t, i18n] = useTranslation("common");

  return (
    <CookieConsent buttonText="Je comprends">
      {t("cookies")}
    </CookieConsent>
  );
};

export default Cookie;
