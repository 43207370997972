import styled from "styled-components";
import { mobile } from "../responsive";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { MdOutlineLocationSearching } from "react-icons/md";
import { BiCartAlt, BiUser } from "react-icons/bi";
import { useState } from "react";
import { AiOutlineEye, AiOutlineLeft } from "react-icons/ai";
import { useEffect } from "react";
import { getUser } from "../redux/apiRequests";
import { getOrderSuccess, logout, updateUserSuccess } from "../redux/userRedux";
import { userRequest } from "../requestMethods";
import countries from "../assets/countries-with-code.json";
import { Pagination } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import loaderSvg from "../assets/loader.svg";
const dayjs = require("dayjs");

const Container = styled.div`
  flex-wrap: wrap;
  max-width: 60rem;
  padding: 5rem 0;
  margin: auto;
  ${mobile({
    margin: "1rem",
    padding: "2rem 0",
  })}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;

  ${mobile({
    flexDirection: "column",
  })}
`;

const Sidebar = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  max-width: 300px;
  margin: 0 1rem 0 0;
  border: 1px solid #e6e6e6;

  ${mobile({
    margin: "0",
    maxWidth: "100%",
    marginBottom: "1rem",
  })}
`;

const SidebarItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &:not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }

  &:hover {
    cursor: pointer;
  }
`;

const SidebarBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const SidebarLink = styled(Link)`
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-decoration: none;
  padding: 0.5rem 0;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  background: ${(props) => (props.active ? "#000" : "transparent")};
  color: ${(props) => (props.active ? "#fff" : "#383838")};

  &:hover {
    text-decoration: none;
    background: #000;
    color: #fff;
  }

  ${mobile({
    fontSize: "1rem",
  })}
`;

const Content = styled.div`
  flex: 4;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  margin: 0;
`;

const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1px solid #e6e6e6;
  padding: 1rem;
`;

const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${mobile({
    width: "100%",
    overflow: "hidden",
  })}
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ebebeb;

  &:not(:first-child) {
    margin-top: 3rem;
  }

  ${mobile({
    fontSize: "1.2rem",
  })}
`;

const CustomLink = styled.div`
  display: flex;
  flex-direction: row;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  ${mobile({
    width: "100%",
  })}
`;

const DetailAccount = styled.div`
  display: flex;
  flex-direction: column;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  &:first-child {
    margin-top: 1rem;
  }

  ${mobile({
    flexDirection: "column",
  })}
`;

const FormGroup = styled.div`
  width: 100%;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 1rem;

  ${mobile({
    margin: "0",
  })}
`;

const FooterGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  margin: 0.5rem;
  border: 1px solid #ccc;

  ${mobile({
    width: "auto",
  })}
`;

const Select = styled.select`
  padding: 0.5rem;
  margin: 0.5rem;
  border: 1px solid #ccc;
  background: #fff;

  ${mobile({
    width: "95%",
  })}
`;

const Button = styled.button`
  width: ${(props) => (props.width ? props.width : "30%")};
  align-self: ${(props) => (props.align ? props.align : "initial")};
  padding: 10px;
  margin: 0.5rem;
  background-color: #000;
  border: none;
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
`;

const CustomButton = styled.button`
  width: ${(props) => (props.width ? props.width : "30%")};
  align-self: ${(props) => (props.align ? props.align : "initial")};
  padding: 10px;
  margin: 0.5rem;
  background-color: #000;
  border: none;
  cursor: pointer;
  color: white;
  margin-bottom: 10px;

  ${mobile({
    width: "50%",
    margin: "0",
  })}
`;

const AddressesList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

const AddressesItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 1rem;
  }
`;

const SubTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: bold;
  padding-bottom: 1rem;

  ${mobile({
    fontSize: "1rem",
  })}
`;

const Text = styled.div`
  font-size: 1.2rem;
  padding: 0.5rem;
  margin: 0.5rem;

  ${mobile({
    fontSize: "1rem",
  })}
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-color: #dee2e6;
  border: 1px solid #ebebeb;
`;

const THead = styled.thead`
  background-color: #f5f5f5;
  border-bottom: 1px solid #ebebeb;
  border-style: solid;
  border-width: 0;
`;

const TBody = styled.tbody`
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid #ebebeb;
  font-size: 0.9rem;
`;

const Head = styled.th`
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.5rem;
  text-align: left;
  border: 1px solid #ebebeb;
  font-size: 1rem;
`;

const Row = styled.tr`
  border: 1px solid #ebebeb;
  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
`;

const Cell = styled.td`
  padding: 0.5rem;
  border: 1px solid #ebebeb;

  ${mobile({
    fontSize: "0.8rem",
  })}
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

const FlatButton = styled(Button)`
  display: flex;
  width: 80%;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #000;

  &:hover {
    color: #ffc107;
  }
`;

const Error = styled.span`
  color: ${({ type }) => type === "error" && "#f00"};
  font-size: 12px;
  margin: 0 0.5rem;

  ${mobile({ width: "75%" })}
`;

const Message = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  color: ${({ type }) => (type === "error" ? "#f00" : "#75c573")};
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 1rem;
  flex: 1;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

const CustomAction = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  width: 100%;
`;

const AddAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
  width: 100%;
  align-items: center;
`;

const CheckBox = styled.input`
  margin: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  background: #fff;
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
`;

const NormalText = styled.div`
  font-size: 1rem;
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
`;

const Subtitle = styled.h2`
  font-size: 1.1rem;
  font-weight: 300;
  padding: 2rem 0px;
`;

const OrderView = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 1rem;
  width: 100%;
`;

const OrderViewHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin: 1.5rem 0px;
`;

const OrderViewHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const OrderViewHeaderDate = styled.div``;

const OrderViewBody = styled.div``;

const OrderViewBodyTitle = styled.div`
  margin-bottom: 1rem;
`;

const OrderViewItems = styled.div``;

const OrderViewItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  align-items: center;
`;

const OrderViewItemImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 1rem;
  aspect-ratio: 1;
  background-color: #fff;
`;

const OrderViewItemName = styled.div``;

const OrderViewFooter = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

const OrderViewFooterStatus = styled.div`
  font-weight: bold;
  margin-left: 0.5rem;
`;

const OrderNumber = styled.span`
  font-weight: bold;
  margin-left: 0.5rem;
`;

const BackButton = styled(Button)`
  display: flex;
  width: 80%;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #000;
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const Image = styled.img`
  width: 115%;
  height: 20px;

  ${mobile({
    width: "130%",
  })}
`;

const Account = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);
  const [active, setActive] = useState("orders");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [t, i18n] = useTranslation("common");
  const [inputs, setInputs] = useState({});
  const [helper, setHelper] = useState({
    firstname: {
      text: "",
      color: "",
    },
    lastname: {
      text: "",
      color: "",
    },
    username: {
      text: "",
      color: "",
    },
    email: {
      text: "",
      color: "",
    },
    password: {
      text: "",
      color: "",
    },
    newpassword: {
      text: t("account.profile.helpers.min_character"),
      color: "",
    },
    confirm: {
      text: "",
      color: "",
    },
    street: {
      text: t("account.profile.helpers.street_hint"),
      color: "",
    },
    city: {
      text: "",
      color: "",
    },
    state: {
      text: "",
      color: "",
    },
    zip: {
      text: "",
      color: "",
    },
    country: {
      text: "",
      color: "",
    },
    principal: {
      text: "",
      color: "",
    },
  });
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });
  const [passwordMessage, setPasswordMessage] = useState({
    text: "",
    type: "",
  });
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState({});
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [orderToShow, setOrderToShow] = useState({});
  const [isShowingOrder, setIsShowingOrder] = useState(false);
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const language = useSelector((state) => state.store.language);

  useEffect(() => {
    if (user.currentUser) {
      try {
        const getOrder = async () => {
          const res = await userRequest.get(
            `/orders/find/${user.currentUser._id}`
          );
          dispatch(getOrderSuccess(res.data));
        };
        getOrder().then(() => {
          splitOrderInPages();
        });
      } catch (err) {
        if (err.message.includes("403")) {
          dispatch(getOrderExpiredFailure());
        }
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (user.currentUser._id) {
      setInputs({
        firstname: user.currentUser.firstname,
        lastname: user.currentUser.lastname,
        username: user.currentUser.username,
        email: user.currentUser.email,
      });
    }

    setMessage({
      text: "",
      type: "",
    });

    setPasswordMessage({
      text: "",
      type: "",
    });

    if (history.location.state && history.location.state.fromCart) {
      setActive("addresses");
      setIsNewAddress(true);
      setAddress({});
    }
  }, []);

  const setAddress = (address) => {
    setInputs({
      street: address.street,
      city: address.city,
      state: address.state,
      zip: address.zip,
      country: address.country,
      principal: address.principal,
    });
    setAddressToEdit(address);
  };

  const resetHelpers = (e) => {
    e.target.name === "firstname" &&
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: "",
            color: "",
          },
        };
      });

    e.target.name === "lastname" &&
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: "",
            color: "",
          },
        };
      });
    e.target.name === "email" &&
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: "",
            color: "",
          },
        };
      });

    e.target.name === "password" &&
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: "",
            color: "",
          },
        };
      });
    e.target.name === "newpassword" &&
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: t("account.profile.helpers.min_character"),
            color: "",
          },
        };
      });

    e.target.name === "confirm" &&
      setHelper((prev) => {
        return {
          ...prev,
          confirm: {
            text: "",
            color: "",
          },
        };
      });

    e.target.name === "street" &&
      setHelper((prev) => {
        return {
          ...prev,
          street: {
            text: t("account.profile.helpers.street_hint"),
            color: "",
          },
        };
      });

    e.target.name === "city" &&
      setHelper((prev) => {
        return {
          ...prev,
          city: {
            text: "",
            color: "",
          },
        };
      });

    e.target.name === "state" &&
      setHelper((prev) => {
        return {
          ...prev,
          state: {
            text: "",
            color: "",
          },
        };
      });

    e.target.name === "zip" &&
      setHelper((prev) => {
        return {
          ...prev,
          zip: {
            text: "",
            color: "",
          },
        };
      });

    e.target.name === "country" &&
      setHelper((prev) => {
        return {
          ...prev,
          country: {
            text: "",
            color: "",
          },
        };
      });

    e.target.name === "principal" &&
      setHelper((prev) => {
        return {
          ...prev,
          principal: {
            text: "",
            color: "",
          },
        };
      });
  };

  const getStatus = (status) => {
    switch (status) {
      case "pending":
        return language === "fr" ? "En attente" : "Pending";
      case "processing":
        return language === "fr" ? "En cours de traitement" : "Processing";
      case "in_transit":
        return language === "fr" ? "En cours de livraison" : "In transit";
      case "delivered":
        return language === "fr" ? "Livré" : "Delivered";
      case "cancelled":
        return language === "fr" ? "Annulé" : "Cancelled";
      default:
        return "";
    }
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    resetHelpers(e);
  };

  const handleCheckboxChange = (e) => {
    setInputs((prev) => {
      return { ...prev, principal: e.target.checked };
    });
    resetHelpers(e);
  };

  useEffect(() => {
    if (!user.currentUser._id) {
      history.push("/login");
    }
  }, [user.currentUser._id]);

  const handleUpdate = async (e) => {
    e.preventDefault();

    setMessage({
      text: "",
      type: "",
    });

    inputs.firstname &&
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.firstname &&
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: t("account.profile.helpers.firstname"),
            color: "error",
          },
        };
      });

    inputs.lastname &&
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.lastname &&
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: t("account.profile.helpers.lastname"),
            color: "error",
          },
        };
      });

    inputs.email &&
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.email &&
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: t("account.profile.helpers.email"),
            color: "error",
          },
        };
      });

    inputs.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputs.email) &&
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: t("account.profile.helpers.invalid_email"),
            color: "error",
          },
        };
      });

    inputs.username &&
      setHelper((prev) => {
        return {
          ...prev,
          username: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.username &&
      setHelper((prev) => {
        return {
          ...prev,
          username: {
            text: t("account.profile.helpers.username"),
            color: "error",
          },
        };
      });

    if (
      inputs.firstname &&
      inputs.lastname &&
      inputs.email &&
      inputs.username
    ) {
      try {
        setIsLoading(true);
        const res = await userRequest.put(
          `/users/user/${user.currentUser._id}`,
          {
            firstname: inputs.firstname,
            lastname: inputs.lastname,
            username: inputs.username,
            email: inputs.email,
          }
        );
        setIsLoading(false);
        dispatch(updateUserSuccess(res.data));
        setMessage({
          text: t("account.success"),
          type: "success",
        });
      } catch (err) {
        setIsLoading(false);
        if (
          err?.message?.includes("409") ||
          err?.response?.data?.code === 11000
        ) {
          setMessage({
            text: t("account.profile.helpers.email_already_used"),
            type: "error",
          });
        } else if (err?.message?.includes("403")) {
          dispatch(logout());
        } else {
          setMessage({
            text: t("account.server_error"),
            type: "error",
          });
        }
      }
    }
  };

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();

    setPasswordMessage({
      text: "",
      type: "",
    });

    inputs.password &&
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: "",
            color: "",
          },
        };
      });

    inputs.newpassword &&
      inputs.confirm &&
      !inputs.password &&
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: t("account.profile.helpers.current_password"),
            color: "error",
          },
        };
      });

    inputs.newpassword &&
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: t("account.profile.helpers.min_character"),
            color: "",
          },
        };
      });

    inputs.newpassword &&
      inputs.newpassword.length < 5 &&
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: t("account.profile.helpers.min_character"),
            color: "error",
          },
        };
      });

    !inputs.newpassword &&
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: t("account.profile.helpers.new_password"),
            color: "error",
          },
        };
      });

    inputs.confirm &&
      setHelper((prev) => {
        return {
          ...prev,
          confirm: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.confirm &&
      inputs.newpassword &&
      setHelper((prev) => {
        return {
          ...prev,
          confirm: {
            text: t("account.profile.helpers.confirm_password"),
            color: "error",
          },
        };
      });

    inputs.newpassword &&
      inputs.confirm &&
      inputs.newpassword !== inputs.confirm &&
      setHelper((prev) => {
        return {
          ...prev,
          confirm: {
            text: t("account.profile.helpers.password_notMatch"),
            color: "error",
          },
        };
      });

    if (
      inputs.password &&
      inputs.newpassword &&
      inputs.confirm &&
      inputs.newpassword === inputs.confirm &&
      inputs.newpassword.length >= 5
    ) {
      try {
        setIsPasswordLoading(true);
        const res = await userRequest.put(
          `/users/user/${user.currentUser._id}`,
          {
            password: inputs.password,
            newpassword: inputs.newpassword,
            confirm: inputs.confirm,
          }
        );
        setIsPasswordLoading(false);
        dispatch(updateUserSuccess(res.data));
        setPasswordMessage({
          text: t("account.profile.helpers.updated_password"),
          type: "success",
        });
      } catch (err) {
        setIsPasswordLoading(false);
        if (err?.response?.data?.message?.includes("Invalid password")) {
          setPasswordMessage({
            text: t("account.profile.helpers.not_correct"),
            type: "error",
          });
        } else if (err?.message?.includes("403")) {
          dispatch(logout());
        } else {
          setPasswordMessage({
            text: t("account.error"),
            type: "error",
          });
        }
      }
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    try {
      setDeleteIsLoading(true);
      let addresses = user.currentUser.addresses;

      if (isEditingAddress) {
        // remove addressToEdit from addresses list
        addresses = addresses.filter(
          (address) => address.street !== addressToEdit.street
        );
      }

      const res = await userRequest.put(`/users/user/${user.currentUser._id}`, {
        addresses: addresses,
      });
      setDeleteIsLoading(false);
      dispatch(updateUserSuccess(res.data));

      setIsNewAddress(false);
      setIsEditingAddress(false);
      setAddress({});
    } catch (err) {
      setDeleteIsLoading(false);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();

    inputs.street &&
      setHelper((prev) => {
        return {
          ...prev,
          street: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.street &&
      setHelper((prev) => {
        return {
          ...prev,
          street: {
            text: t("account.profile.helpers.required"),
            color: "error",
          },
        };
      });

    inputs.city &&
      setHelper((prev) => {
        return {
          ...prev,
          city: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.city &&
      setHelper((prev) => {
        return {
          ...prev,
          city: {
            text: t("account.profile.helpers.city"),
            color: "error",
          },
        };
      });

    inputs.state &&
      setHelper((prev) => {
        return {
          ...prev,
          state: {
            text: "",
            color: "",
          },
        };
      });

    inputs.zip &&
      setHelper((prev) => {
        return {
          ...prev,
          zip: {
            text: "",
            color: "",
          },
        };
      });

    inputs.country &&
      setHelper((prev) => {
        return {
          ...prev,
          country: {
            text: "",
            color: "",
          },
        };
      });

    !inputs.country &&
      setHelper((prev) => {
        return {
          ...prev,
          country: {
            text: t("account.profile.helpers.country"),
            color: "error",
          },
        };
      });

    if (inputs.street && inputs.city && inputs.country) {
      try {
        setIsLoading(true);
        let addresses = user.currentUser.addresses;

        // verify if address exist
        const addressExist = addresses.find(
          (address) =>
            address.street === inputs.street &&
            address.city === inputs.city &&
            address.state === inputs.state &&
            address.zip === inputs.zip &&
            address.country === inputs.country
        );

        if (isEditingAddress) {
          // remove addressToEdit from addresses list
          addresses = addresses.filter(
            (address) => address.street !== addressToEdit.street
          );
        }

        // check if addresses has one principal address
        const principalAddress = addresses.find(
          (address) => address.principal === true
        );

        if (principalAddress && isEditingAddress && inputs.principal === true) {
          setHelper((prev) => {
            return {
              ...prev,
              principal: {
                text: t("account.addresses.alreadyHave"),
                color: "error",
              },
            };
          });
          setIsLoading(false);
          return true;
        }

        if (!addressExist || (isEditingAddress && addressExist)) {
          const res = await userRequest.put(
            `/users/user/${user.currentUser._id}`,
            {
              addresses: [
                ...addresses,
                {
                  street: inputs.street,
                  city: inputs.city,
                  state: inputs.state,
                  zip: inputs.zip,
                  country: inputs.country,
                  principal:
                    addresses.length === 0 ||
                    (!principalAddress &&
                      inputs.principal &&
                      inputs.principal === false)
                      ? true
                      : inputs.principal === true
                      ? true
                      : false,
                },
              ],
            }
          );
          dispatch(updateUserSuccess(res.data));
          setIsLoading(false);
          setIsNewAddress(false);
          setIsEditingAddress(false);
          setAddress({});
          history.location.state &&
            history.location.state.fromCart &&
            setTimeout(() => {
              history.push({
                pathname: "/cart",
                state: {
                  fromCart: true,
                },
              });
            }, 10);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  };

  const encoreURI = (asset_url) => {
    // encode ( by %)
    return encodeURI(asset_url);
  };

  const splitOrderInPages = () => {
    const ordersPerPage = [];
    const ordersPerPageLength = 5;
    let index = 0;
    let page = [];
    user?.currentUser &&
      user?.currentUser?.orders?.length > 0 &&
      user?.currentUser?.orders?.forEach((order) => {
        page.push(order);
        index++;
        if (index % ordersPerPageLength === 0) {
          ordersPerPage.push(page);
          page = [];
        }
      });
    if (page.length > 0) {
      ordersPerPage.push(page);
    }
    setOrders(ordersPerPage);
  };

  useEffect(() => {
    if (user.currentUser) {
      getUser(user.currentUser._id, dispatch);
    }
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{t("account.title")}</title>
      </Helmet>
      <Container>
        <Title>{t("account.title")}</Title>
        <Wrapper>
          <Sidebar>
            <SidebarBody>
              <SidebarItem>
                <SidebarLink
                  to="#"
                  active={active === "orders" && "active"}
                  onClick={() => {
                    setActive("orders");
                    setIsShowingOrder(false);
                    setOrderToShow({});
                  }}
                >
                  <CustomLink>
                    <BiCartAlt
                      style={{
                        fontSize: 20,
                        padding: "0 0.5rem",
                        fontWeight: "normal",
                      }}
                    />
                    {t("account.menu.orders")}
                  </CustomLink>
                </SidebarLink>
              </SidebarItem>
              <SidebarItem>
                <SidebarLink
                  to="#"
                  active={active === "addresses" && "active"}
                  onClick={() => {
                    setActive("addresses");
                    setIsNewAddress(false);
                    setIsEditingAddress(false);
                    setAddress({});
                  }}
                >
                  <CustomLink>
                    <MdOutlineLocationSearching
                      style={{
                        fontSize: 20,
                        padding: "0 0.5rem",
                        fontWeight: "normal",
                      }}
                    />
                    {t("account.menu.addresses")}
                  </CustomLink>
                </SidebarLink>
              </SidebarItem>
              <SidebarItem>
                <SidebarLink
                  to="#"
                  active={active === "profile" && "active"}
                  onClick={() => setActive("profile")}
                >
                  <CustomLink>
                    <BiUser style={{ fontSize: 20, padding: "0 0.5rem" }} />
                    {t("account.menu.profile")}
                  </CustomLink>
                </SidebarLink>
              </SidebarItem>
            </SidebarBody>
          </Sidebar>
          <Content>
            <ContentBody>
              <ContentItem
                style={
                  active === "orders"
                    ? {
                        visibility: "visible",
                        opacity: 1,
                        transition: "opacity 150ms linear",
                        height: "100%",
                        overflow: "initial",
                      }
                    : {
                        visibility: "hidden",
                        opacity: 0,
                        transition: "visibility 0s 150ms, opacity 150ms linear",
                        height: 0,
                        overflow: "hidden",
                      }
                }
              >
                <Title>{t("account.menu.orders")}</Title>
                {user.currentUser &&
                  user?.currentUser?.orders?.length > 0 &&
                  !isShowingOrder && (
                    <Subtitle>{t("account.orders.subtitle")}</Subtitle>
                  )}
                <div style={{ overflowX: "auto", width: "100%" }}>
                  {user.currentUser &&
                    user?.currentUser?.orders?.length > 0 &&
                    !isShowingOrder && (
                      <Table>
                        <THead>
                          <Row>
                            <Head>{t("account.orders.table.th.date")}</Head>
                            <Head>{t("account.orders.table.th.products")}</Head>
                            <Head>{t("account.orders.table.th.status")}</Head>
                            <Head>{t("account.orders.table.th.action")}</Head>
                          </Row>
                        </THead>
                        <TBody>
                          {user.currentUser &&
                            orders[page - 1]?.map((order, index) => (
                              <Row key={index}>
                                <Cell>
                                  {language === "fr"
                                    ? dayjs(order.createdAt).format(
                                        "DD/MM/YYYY"
                                      )
                                    : dayjs(order.createdAt).format(
                                        "MM/DD/YYYY"
                                      )}
                                </Cell>
                                <Cell>
                                  {order.products.map((product, i) => (
                                    <Item key={i}>
                                      {language === "fr"
                                        ? product.productId.name
                                        : product.productId.en.name}
                                    </Item>
                                  ))}
                                </Cell>
                                <Cell>{getStatus(order.status)}</Cell>
                                <Cell>
                                  <FlatButton
                                    onClick={() => {
                                      setOrderToShow(order);
                                      setIsShowingOrder(true);
                                    }}
                                  >
                                    <AiOutlineEye />
                                    {t("account.orders.view")}
                                  </FlatButton>
                                </Cell>
                              </Row>
                            ))}
                        </TBody>
                      </Table>
                    )}
                  {user.currentUser && orders?.length > 1 && !isShowingOrder && (
                    <PaginationContainer>
                      <Pagination
                        noMargin
                        style={{ marginTop: "1rem" }}
                        controls={false}
                        color="warning"
                        total={orders?.length}
                        initialPage={page}
                        onChange={(e) => setPage(e)}
                      />
                    </PaginationContainer>
                  )}
                  {user.currentUser && user?.currentUser?.orders?.length === 0 && (
                    <Empty
                      style={{
                        height: "5rem",
                      }}
                    >
                      {t("account.orders.empty")}
                    </Empty>
                  )}
                  {isShowingOrder && (
                    <OrderView>
                      <OrderViewHeader>
                        <OrderViewHeaderTitle>
                          {t("account.orders.orderView.order")}
                          <OrderNumber>{orderToShow?.orderNumber}</OrderNumber>
                        </OrderViewHeaderTitle>
                        <OrderViewHeaderDate>
                          {dayjs(orderToShow?.createdAt).format("DD/MM/YYYY")}
                        </OrderViewHeaderDate>
                      </OrderViewHeader>
                      <OrderViewBody>
                        <OrderViewBodyTitle>
                          {t("account.orders.table.th.products")}
                        </OrderViewBodyTitle>
                        <OrderViewItems>
                          {orderToShow?.products.map((product, i) => (
                            <OrderViewItem key={i}>
                              <OrderViewItemImage
                                src={`${
                                  process.env.REACT_APP_ASSETS_URL
                                }/products/${encoreURI(
                                  product.productId.images[0]
                                )}`}
                              />
                              <OrderViewItemName>
                                {language === "fr"
                                  ? product.productId.name
                                  : product.productId.en.name}
                              </OrderViewItemName>
                            </OrderViewItem>
                          ))}
                        </OrderViewItems>
                      </OrderViewBody>
                      <OrderViewFooter>
                        {t("account.orders.orderView.status")}
                        <OrderViewFooterStatus>
                          {getStatus(orderToShow?.status)}
                        </OrderViewFooterStatus>
                      </OrderViewFooter>
                      <BackButton onClick={() => setIsShowingOrder(false)}>
                        <AiOutlineLeft />
                        {t("account.back")}
                      </BackButton>
                    </OrderView>
                  )}
                </div>
              </ContentItem>
              <ContentItem
                style={
                  active === "addresses"
                    ? {
                        visibility: "visible",
                        opacity: 1,
                        transition: "opacity 150ms linear",
                        height: "100%",
                        overflow: "initial",
                      }
                    : {
                        visibility: "hidden",
                        opacity: 0,
                        transition: "visibility 0s 150ms, opacity 150ms linear",
                        height: 0,
                        overflow: "hidden",
                      }
                }
              >
                <Title>{t("account.menu.addresses")}</Title>
                {!isNewAddress && !isEditingAddress && (
                  <>
                    {user?.currentUser?.addresses?.length === 0 && (
                      <Empty>{t("account.addresses.empty")}</Empty>
                    )}
                    <AddressesList>
                      {user?.currentUser?.addresses?.map((address, index) => {
                        return (
                          <AddressesItem key={index}>
                            <List>
                              <SubTitle>
                                {address.principal
                                  ? language === "fr"
                                    ? "Principal"
                                    : "Primary"
                                  : language === "fr"
                                  ? "Autre"
                                  : "Other"}
                              </SubTitle>
                              <Text>
                                {address.street}, {address.state}, {address.zip}
                              </Text>
                              <Text>
                                {
                                  countries.fr.find(
                                    (country) =>
                                      country.code === address.country
                                  ).name
                                }
                              </Text>
                            </List>
                            <Action>
                              <Button
                                width="100%"
                                align="end"
                                onClick={() => {
                                  setAddress(address);
                                  setIsEditingAddress(true);
                                }}
                              >
                                {t("account.edit")}
                              </Button>
                            </Action>
                          </AddressesItem>
                        );
                      })}
                    </AddressesList>
                  </>
                )}
                {isNewAddress && <Text> {t("account.addresses.add")}</Text>}
                {isEditingAddress && (
                  <Text> {t("account.addresses.edit")}</Text>
                )}
                {(isNewAddress || isEditingAddress) && (
                  <Form>
                    <Group>
                      <FormGroup>
                        <Input
                          type="text"
                          placeholder={language === "fr" ? "Rue" : "Street"}
                          name="street"
                          value={inputs.street}
                          onChange={handleChange}
                        />
                        <Error
                          type={
                            helper.street.color === "error" ? "error" : "info"
                          }
                        >
                          {helper.street.text}
                        </Error>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type="text"
                          placeholder={language === "fr" ? "Ville" : "City"}
                          name="city"
                          value={inputs.city}
                          onChange={handleChange}
                        />
                        <Error
                          type={
                            helper.city.color === "error" ? "error" : "info"
                          }
                        >
                          {helper.city.text}
                        </Error>
                      </FormGroup>
                    </Group>
                    <Group>
                      <FormGroup>
                        <Input
                          type="text"
                          placeholder={
                            language === "fr"
                              ? "Etat/Province"
                              : "State/Province"
                          }
                          name="state"
                          value={inputs.state}
                          onChange={handleChange}
                        />
                        <Error
                          type={
                            helper.state.color === "error" ? "error" : "info"
                          }
                        >
                          {helper.state.text}
                        </Error>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type="text"
                          placeholder={
                            language === "fr" ? "Code postal" : "Zip code"
                          }
                          name="zip"
                          value={inputs.zip}
                          onChange={handleChange}
                        />
                        <Error
                          type={helper.zip.color === "error" ? "error" : "info"}
                        >
                          {helper.zip.text}
                        </Error>
                      </FormGroup>
                    </Group>
                    <Group>
                      <FormGroup>
                        <Select
                          type="text"
                          placeholder={language === "fr" ? "Pays" : "Country"}
                          name="country"
                          value={inputs.country}
                          onChange={handleChange}
                        >
                          <option value="">
                            {t("account.addresses.chooseCountry")}
                          </option>
                          {countries.fr.map((country) => (
                            <option key={country.code} value={country.code}>
                              {country.name}
                            </option>
                          ))}
                        </Select>
                        <Error
                          type={
                            helper.country.color === "error" ? "error" : "info"
                          }
                        >
                          {helper.country.text}
                        </Error>
                      </FormGroup>
                    </Group>
                    {!isNewAddress && (
                      <Group>
                        <FormGroup>
                          <CheckBoxContainer>
                            <CheckBox
                              type="checkbox"
                              name="principal"
                              checked={inputs.principal}
                              onChange={handleCheckboxChange}
                            />
                            <NormalText>
                              {t("account.addresses.primaryAddress")}
                            </NormalText>
                          </CheckBoxContainer>
                          <Error
                            type={
                              helper.principal.color === "error"
                                ? "error"
                                : "info"
                            }
                          >
                            {helper.principal.text}
                          </Error>
                        </FormGroup>
                      </Group>
                    )}
                    <FooterGroup>
                      <CustomAction style={{ justifyContent: "space-between" }}>
                        <Button onClick={handleClick} disabled={isLoading}>
                          {isLoading ? (
                            <Image src={loaderSvg} alt="loader" />
                          ) : isEditingAddress ? (
                            t("account.update")
                          ) : (
                            t("account.add")
                          )}
                        </Button>
                        {isEditingAddress && (
                          <Button
                            onClick={handleDelete}
                            disabled={deleteIsLoading}
                          >
                            {deleteIsLoading ? (
                              <Image src={loaderSvg} alt="loader" />
                            ) : (
                              t("account.delete")
                            )}
                          </Button>
                        )}
                      </CustomAction>
                    </FooterGroup>
                  </Form>
                )}
              </ContentItem>
              <ContentItem
                style={
                  active === "profile"
                    ? {
                        visibility: "visible",
                        opacity: 1,
                        transition: "opacity 150ms linear",
                        height: "100%",
                        overflow: "initial",
                      }
                    : {
                        visibility: "hidden",
                        opacity: 0,
                        transition: "visibility 0s 150ms, opacity 150ms linear",
                        height: 0,
                        overflow: "hidden",
                      }
                }
              >
                <Title>{t("account.profile.title")}</Title>
                <Message type={message.type}>{message.text}</Message>

                <DetailAccount>
                  <Form>
                    <Group>
                      <FormGroup>
                        <Input
                          type="text"
                          placeholder={language === "fr" ? "Nom" : "Last name"}
                          name="lastname"
                          value={inputs.lastname}
                          onChange={handleChange}
                        />
                        <Error
                          type={
                            helper.lastname.color === "error" ? "error" : "info"
                          }
                        >
                          {helper.lastname.text}
                        </Error>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type="text"
                          placeholder={
                            language === "fr" ? "Prénom" : "First name"
                          }
                          name="firstname"
                          value={inputs.firstname}
                          onChange={handleChange}
                        />
                        <Error
                          type={
                            helper.firstname.color === "error"
                              ? "error"
                              : "info"
                          }
                        >
                          {helper.firstname.text}
                        </Error>
                      </FormGroup>
                    </Group>
                    <Group>
                      <FormGroup>
                        {" "}
                        <Input
                          type="email"
                          placeholder={
                            language === "fr" ? "Email" : "Email address"
                          }
                          name="email"
                          value={inputs.email}
                          onChange={handleChange}
                        />
                        <Error
                          type={
                            helper.email.color === "error" ? "error" : "info"
                          }
                        >
                          {helper.email.text}
                        </Error>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type="text"
                          placeholder={
                            language === "fr" ? "Nom d'utilisateur" : "Username"
                          }
                          name="username"
                          value={inputs.username}
                          onChange={handleChange}
                        />
                        <Error
                          type={
                            helper.username.color === "error" ? "error" : "info"
                          }
                        >
                          {helper.username.text}
                        </Error>
                      </FormGroup>
                    </Group>
                    <FormGroup>
                      <Button onClick={handleUpdate} disabled={isLoading}>
                        {isLoading
                          ? t("account.profile.in_progress")
                          : t("account.edit")}
                      </Button>
                    </FormGroup>
                  </Form>
                </DetailAccount>

                <Title>{t("account.profile.change_password")}</Title>
                <Message type={passwordMessage.type}>
                  {passwordMessage.text}
                </Message>

                <DetailAccount>
                  <Form>
                    <Group>
                      <FormGroup>
                        <Input
                          type="password"
                          name="password"
                          placeholder={
                            language === "fr" ? "Mot de passe" : "Password"
                          }
                          onChange={handleChange}
                        />
                        <Error
                          type={
                            helper.password.color === "error" ? "error" : "info"
                          }
                        >
                          {helper.password.text}
                        </Error>
                      </FormGroup>
                    </Group>
                    <Group>
                      <FormGroup>
                        <Input
                          type="password"
                          name="newpassword"
                          placeholder={
                            language === "fr"
                              ? "Nouveau mot de passe"
                              : "New password"
                          }
                          onChange={handleChange}
                        />
                        <Error
                          type={
                            helper.newpassword.color === "error"
                              ? "error"
                              : "info"
                          }
                        >
                          {helper.newpassword.text}
                        </Error>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type="password"
                          name="confirm"
                          placeholder={
                            language === "fr"
                              ? "Confirmer le nouveau mot de passe"
                              : "Confirm new password"
                          }
                          onChange={handleChange}
                        />
                        <Error
                          type={
                            helper.confirm.color === "error" ? "error" : "info"
                          }
                        >
                          {helper.confirm.text}
                        </Error>
                      </FormGroup>
                    </Group>
                    <FormGroup>
                      <Button
                        onClick={handlePasswordUpdate}
                        disabled={isPasswordLoading}
                      >
                        {isPasswordLoading
                          ? t("account.profile.in_progress")
                          : t("account.edit")}
                      </Button>{" "}
                    </FormGroup>
                  </Form>
                </DetailAccount>
              </ContentItem>
            </ContentBody>
            {!isNewAddress && !isEditingAddress && active === "addresses" && (
              <AddAddressContainer>
                <CustomButton
                  width="30%"
                  onClick={() => {
                    setIsNewAddress(true);
                    setAddress({});
                  }}
                >
                  {t("account.addresses.add")}
                </CustomButton>
              </AddAddressContainer>
            )}
          </Content>
        </Wrapper>
      </Container>
    </>
  );
};

export default Account;
