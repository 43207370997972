import { useState } from "react";
import styled from "styled-components";
import { mobile, tablet, touch } from "../responsive";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { send } from "../redux/apiRequests";
import Ring from "../@components/spinner";
import { useEffect } from "react";
import countries from "../assets/countries.json";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 80rem;
  margin: auto;
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
  ${mobile({ padding: "0px 10px" })}
  ${touch({ padding: "0px 24px" })}
`;

const Title = styled.h1`
  font-weight: bold;
  padding: 2rem;
  ${mobile({
  fontSize: "20px",
  margin: 0,
  padding: "1rem",
})}
`;

const Section = styled.section`
  margin-top: 2rem;
  margin-bottom: 2rem;
  ${mobile({
  marginTop: "1rem",
})}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${tablet({ flexDirection: "column" })}
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  ${tablet({ width: "100%" })}
  ${mobile({ width: "100%" })}
`;

const Subtitle = styled.h2`
  font-weight: bold;
  margin-bottom: 1rem;
  ${mobile({
  fontSize: "18px",
})}
`;

const Text = styled.p`
  margin-bottom: 1rem;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 80rem;
  margin: auto;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  margin: 1rem;
  padding: 5rem 4rem;
  overflow: hidden;
  width: 50%;
  ${touch({ margin: 0, padding: "2rem", width: "80%" })}
`;

const CardTitle = styled.h2`
  font-weight: bold;
  margin-bottom: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Label = styled.label`
  font-size: 1rem;
  width: 100%;
  margin-left: -2rem;
  margin-bottom: 0.4rem;
`;

const TextInput = styled.input`
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const TextArea = styled.textarea`
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const Button = styled.button`
  display: block;
  width: 100%;
  background-color: #e14;
  border: none;
  border-radius: 3px;
  padding: 1ch;
  font-size: 1rem;
  color: #fff;
  font-family: inherit;
  margin-top: 1rem;
  cursor: pointer;
`;

const ErrorText = styled.div`
  color: red;
  width: 100%;
  margin-left: -1.5rem;
`;

const ErrorEmailText = styled.div`
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  margin-left: -1.5rem;
  font-size: 12px;
  color: red;
  width: 100%;
`;

const SuccessText = styled.div`
  color: green;
  margin-left: -1.5rem;
  max-width: 20rem;
`;

const Phone = styled.div`
  margin-bottom: 1rem;
  margin: 0.5rem 0;
`;

const ContactUs = () => {
  const sidebarState = useSelector((state) => state.sidebar.open);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subjet, setSubjet] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [response, setResponse] = useState(false);
  const [error, setError] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const store = useSelector((state) => state.store.stores[0]);
  const [phones, setPhones] = useState([]);
  const [t, i18n] = useTranslation("common");
  const language = useSelector((state) => state.store.language);

  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    groupPhonesByCountry();
  }, []);

  const groupPhonesByCountry = () => {
    const phonesByCountry = {};
    // extrate country code from phone number
    store.others.forEach((phone) => {
      const countryCode = phone.split(" ")[0];

      if (phonesByCountry[countryCode]) {
        phonesByCountry[countryCode].push(phone);
      } else {
        phonesByCountry[countryCode] = [phone];
      }
    });
    const phonesList = phonesByCountryList(phonesByCountry);
    phonesList.map((phone) => {
      if (phone.country === "Canada") {
        phone.country = "Canada / Usa";
      }
      return phone;
    });
    setPhones(phonesList);
  };

  // map country code to country name
  const phonesByCountryList = (phonesByCountry) => {
    const res = {};
    Object.keys(phonesByCountry).forEach((countryCode) => {
      const country = getCountryNameByCode(countryCode);
      res[country] = phonesByCountry[countryCode];
    });
    // convert jspn object to array
    const list = Object.keys(res).map((country) => {
      return {
        country,
        phones: res[country],
      };
    });
    return list;
  };

  // map list of phones by country code to list of phones by country name
  const getCountryNameByCode = (countryCode) => {
    const country = language === 'en' ? countries.list.en.find((c) => c.code === countryCode)
      : countries.list.fr.find((c) => c.code === countryCode);
    return country.name;
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponse(false);
    setRequestError(false);
    setError(false);

    if (name !== "" && email !== "" && subjet !== "" && message !== "") {
      await send(name, email, subjet, message)
        .then(function () {
          setLoading(false);
          setStatus(true);
          setResponse(true);
        })
        .catch(function () {
          setLoading(false);
          setRequestError(true);
        });
    } else {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <Container sidebarState={sidebarState}>
      <Helmet>
        <title>{t("contact.title")} - Laboratoire Luxe Cosmétique</title>
      </Helmet>
      <Title>
        {t("contact.title")}
      </Title>
      <Section>
        <Row>
          <Col>
            <Subtitle>
              {t("contact.subtitle")}
            </Subtitle>

            <Text>
              {t("contact.text")}
            </Text>

            <Subtitle>
              {t("contact.call")}
            </Subtitle>

            {phones.map((phone) => (
              <Text
                style={{ fontWeight: 500, textTransform: "uppercase" }}
                key={phone.country}
              >
                {phone.country} :{" "}
                {phone.phones.map((phoneNumber) => (
                  <>
                    <Phone style={{ fontWeight: 300 }} key={phoneNumber}>
                      {(phone.country === "Royaume-Uni" || phone.country === "United Kingdom") && (`🇬🇧 ${phoneNumber}`)}
                      {(phone.country === "Allemagne" || phone.country === "Germany") && (`🇩🇪 ${phoneNumber}`)}
                      {phone.country === "Canada / Usa" && (`🇨🇦/🇺🇸 ${phoneNumber}`)}
                      {(phone.country === "Cameroun" || phone.country === "Cameroon") && (`🇨🇲 ${phoneNumber}`)}
                      {phone.country === "Congo" && (`🇨🇩 ${phoneNumber}`)}
                      {(phone.country === "Côte d'Ivoire" || phone.country === "Ivory Coast") && (`🇨🇮 ${phoneNumber}`)}
                      {phone.country === "Togo" && (`🇹🇬 ${phoneNumber}`)}
                      {(phone.country === "Bénin" || phone.country === "Benin") && (`🇧🇯 ${phoneNumber}`)}
                      {(phone.country === "Nigéria" || phone.country === "Nigeria") && (`🇳🇬 ${phoneNumber}`)}
                      {phone.country === "Ghana" && (`🇬🇭 ${phoneNumber}`)}
                      {phone.country === "France" && (`🇫🇷 ${phoneNumber}`)}
                      {phone.country === "Sénégal" && (`🇸🇳 ${phoneNumber}`)}
                    </Phone>
                  </>
                ))}
              </Text>
            ))}
          </Col>
          <Col>
            <FormWrapper>
              <Card>
                <CardTitle>
                  {t("contact.form.title")}
                </CardTitle>
                <Form>
                  <Label>
                    {t("contact.form.name")}
                  </Label>

                  <TextInput onChange={(e) => setName(e.target.value)} />

                  <Label>
                    {t("contact.form.email")}
                  </Label>

                  <TextInput onChange={(e) => setEmail(e.target.value)} />
                  {requestError && !regex.test(email) && (
                    <ErrorEmailText>
                      {t("contact.form.invalid_email")}
                    </ErrorEmailText>
                  )}

                  <Label>
                    {t("contact.form.subject")}
                  </Label>

                  <TextInput onChange={(e) => setSubjet(e.target.value)} />

                  <Label>
                    {t("contact.form.message")}
                  </Label>

                  <TextArea onChange={(e) => setMessage(e.target.value)} />

                  {error && <ErrorText>
                    {t("contact.form.required")}
                  </ErrorText>}

                  {response && requestError && (
                    <ErrorText>
                      {t("contact.form.error")}
                    </ErrorText>
                  )}
                  {response && status && (
                    <SuccessText>
                      {t("contact.form.success")}
                    </SuccessText>
                  )}
                  <Button onClick={handleClick}>
                    {loading ? (
                      <Ring size="16" color="#fff" strokeColor="#e14" />
                    ) : (
                      t("contact.form.send")
                    )}
                  </Button>
                </Form>
              </Card>
            </FormWrapper>
          </Col>
        </Row>
      </Section>
    </Container>
  );
};

export default ContactUs;
