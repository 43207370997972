import styled from "styled-components";
import Ranges from "../components/Ranges";
import { useSelector } from "react-redux";

const Container = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
`;

const RangeList = () => {
  const sidebarState = useSelector((state) => state.sidebar.open);

  return (
    <Container sidebarState={sidebarState}>
      <Ranges />
    </Container>
  );
};

export default RangeList;
